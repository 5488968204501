@import '../../assets/styles/mixins.scss';
@import '../../assets/styles/_variables.scss';
@import '../../assets/styles/_typography.scss';

.productSalesPrice {
  p {
    margin: 0 !important;
  }

  .salesPriceOuterContainer {
    @include range-screen(tiny, xmedium) {
      padding: 0em 1.5em !important;
    }
    @include min-screen(xmedium) {
      display: flex;
      padding: 0em 4em !important;
    }

    .salesPriceLeftWidth {
      width: $leftTabWidth;
      @include min-screen(xmedium) {
        width: 0 !important;
      }
    }

    .salesPriceColumnAlign {
      display: flex;
      flex-direction: column;
      gap: 2rem;
    }
  }

  .salesPriceNote {
    padding: 1.5em 0em 0em 0em;
  }

  .availablelanglink {
    padding-top: 25px;
    .MuiButton-root {
      background: none;
      background-color: $white !important;
      color: inherit;
      border: none !important;
      padding: 0;
      font: inherit;
      cursor: pointer;
      outline: inherit;
      .MuiButton-label {
        font-family: $font-family-primary;
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 22px;
        text-decoration-line: underline;
        color: $accent-blue;
      }
    }
  }

  .productAddToCart {
    padding: 2rem 0em 0em 0em;
    .MuiButton-root {
      background: #17457a;
      border: 3px solid #17457a;
      border-radius: 34px;
      .MuiButton-label {
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 22px;
      }
    }
  }
}
