@import '../../assets/styles/_variables.scss';
@import '../../assets/styles/mixins';

.promotionalbanner {
  position: relative;
  background-size: 100% 100% !important;
  background-repeat: no-repeat !important;
  box-shadow: 0 0 5px #888;
  .TextColumn {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100% !important;
    .PromotionText {
      display: flex;
      gap: 0.5em;
      margin: 1.5em;
      width: 100% !important;
      .button {
        display: flex;
        place-items: center;
      }
      .BannerText {
        display: flex;
        justify-content: space-between;
        width: 100%;
        align-items: center;
        flex-direction: column;
      }
    }
    .toastClose {
      font-size: 38px;
      cursor: pointer;
      height: fit-content;
    }
    .ButtonText {
      text-wrap: nowrap;
      min-height: 0em !important;
      text-decoration: none;
    }
  }
  .title {
    font-size: 24px;
    font-weight: bolder;
    margin-bottom: 0.5em;
  }
  .description {
    font-size: 20px;
    @include max-screen(medium) {
      display: none;
    }
  }
}
