@import '../../assets/styles/_mixins.scss';
@import '../../assets/styles/_variables.scss';
@import '../../assets/styles/_typography.scss';
.orderhistory {
  font-family: $font-family-primary !important;
  .toast-container {
    width: 100%;
    position: unset !important;
  }
  .filter-content {
    display: flex;
    flex-direction: row;
    margin-bottom: 1em;
    justify-content: left;
    background: $lily-white;
    margin: 0em 2.6em 1em;
    padding: 0.3em !important;
    border-radius: 3px;
    width: unset !important;
    @include max-screen(medium) {
      margin: 0em 1em 1em !important;
    }
    .MuiGrid-grid-lg-3 {
      max-width: unset !important;
      flex-grow: 1 !important;
    }
    .MuiSelect-selectMenu {
      font-family: $font-family-primary;
    }
  }
  .p-3 {
    padding: 0em !important;
  }
  .toast {
    width: 100%;
  }
  .toast-body {
    background: $alert-red !important;
    font-size: 18px !important;
  }
  .toast-header {
    background: $alert-red !important;
    font-size: 18px !important;
  }
  .toastSection {
    position: unset !important;
  }
  .btn-close {
    color: $white !important;
  }
  .orderHisoryHeading {
    margin: 0;
    padding: 1em 0em 0em 1.2em;
    white-space: nowrap;
    @include max-screen(medium) {
      padding: 1em 0em 0em 0.5em;
    }
  }
  .downloadplugin {
    padding-bottom: 0.5em;
  }

  .orderhistoryheader {
    display: flex;
    justify-content: space-between;
    @include max-screen(small) {
      flex-wrap: wrap;
    }
    .orderhistorydiv {
      display: flex;
      @include max-screen(small) {
        flex-direction: column;
      }
    }
  }
  .order-history-loading-div {
    padding: 0 2.6em;
  }
  .accordionOrderHistory {
    padding-bottom: 1em;
    margin: 0 !important;

    .MuiCollapse-entered {
      border: 1px solid $grayish-blue;
      border-bottom: none;
    }
    .accordionHeader {
      background-color: $lily-white;
      color: $white;
      flex-direction: row-reverse;
      padding: 0;
      border: 2px solid #cdced0;
      padding-top: 1.5rem;
      padding-bottom: 1.5rem;

      .OrderHistoryMainContainer {
        .OrderHeaderLeftContainer {
          --n: 4;
          display: grid;
          grid-template-columns: repeat(auto-fill, minmax(max(200px, 100% / var(--n)), 1fr));
          .LeftGridItem {
            align-self: center !important;
          }
        }
        .OrderHeaderRightContainer {
          align-self: center;
          text-align: right;
          padding-right: 2rem;
          @include max-screen(large) {
            text-align: left !important;
            padding: 1rem 0rem !important;
          }
          .viewReceiptLink {
            font-weight: bold;
          }
          .dropbtn {
            background-color: $white;
            border: 1px solid #c7c9c8 !important;
            color: $black;
            padding: 0.5em 0.75em;
            font-size: 16px;
            border: none;
            svg {
              vertical-align: middle;
              padding-left: 0.5em;
              color: $primary-blue;
            }
          }

          .dropdown {
            position: relative;
            display: inline-block;
          }

          .dropdown-content {
            display: none;
            // display: block;
            position: absolute;
            min-width: 176px;
            box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
            z-index: 1;
            background-color: white;
          }

          .dropdown-content a {
            color: $black;
            padding: 1rem;
            text-decoration: none;
            display: block;
            font-size: 16px;
            font-weight: 400;
            text-align: left;
            // white-space: nowrap;
            word-wrap: break-word;
          }

          .dropdown-content a:hover {
            background-color: #ddd;
          }

          .dropdown:hover .dropdown-content {
            display: block;
          }

          .dropdown:hover .dropbtn {
            background-color: #ddd;
          }
        }
      }

      .MuiAccordionSummary-content {
        margin: 0;
      }
      .orderHistoryHeaderLeft {
        display: flex;
        flex-grow: 1;
        transition: margin 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      }
      .orderHistoryHeaderRight {
        align-self: center;
        min-width: 25%;
        text-align: center;
        background: $accent-blue;
        padding-top: 3.5rem;
        padding-bottom: 3.5rem;
        .viewReceipt {
          font-size: 18px;
          font-family: $font-family-primary;
          font-weight: 700;
          color: $white;
          text-decoration: none;
          display: flex;
          align-items: center;
          justify-content: center;
          svg {
            height: 21px;
            vertical-align: middle;
            margin-right: 10px;
          }
        }
      }
      .Mui-expanded {
        color: $white;
        padding: 0 !important;
        margin: 0 !important;
        .MuiIconButton-label {
          padding-right: 25px !important;
          padding-left: 23px !important;
        }
        .expandAccordion {
          display: none;
          fill: $accent-blue !important;
        }
        .closeAccordion {
          display: block !important;
          fill: $accent-blue !important;
        }
      }
      .MuiIconButton-edgeEnd {
        color: $accent-blue;
        padding: 0;
        .MuiIconButton-label {
          padding-right: 35px;
          padding-left: 25px;
        }
        .closeAccordion {
          display: none;
        }
      }
      .accordionColumn {
        flex-basis: 20.33%;
        align-self: center;
        .accordionHeading {
          font-weight: 700;
          font-size: 18px;
          line-height: 22px;
          color: $primary-blue;
          font-family: $font-family-primary;
        }
        .accordionHeadingValue {
          font-weight: 400;
          font-size: 14px;
          line-height: 35px;
          color: $primary-blue;
          font-family: $font-family-primary;
        }
      }
    }
    .accordionDetails {
      align-items: center;
      padding: 0;
      .accordionBodySection {
        width: -webkit-fill-available;
      }
      .orderDetailsGrid {
        margin: 0;
        width: -webkit-fill-available;
        padding-left: 2em;
        padding-top: 1.2em;
        padding-bottom: 1.2em;
        border-bottom: 1px solid $grayish-blue;
        .accordionBodySegment {
          width: 90%;
          .productTitle {
            font-family: $font-family-primary;
            color: $accent-blue;
            font-weight: 700;
            font-size: 24px;
          }
          .productCode {
            font-weight: 400;
            font-size: 14px;
            font-family: $font-family-primary;
            color: $primary-blue;
          }
          .product-header {
            font-weight: 700;
            font-size: 14px;
            font-family: $font-family-primary;
            color: $black;
          }
          .product-value {
            font-weight: 400;
            font-size: 14px;
            font-family: $font-family-primary;
            color: $black;
          }
        }
      }
      .order-quantity {
        margin: 0;
        width: -webkit-fill-available;
        padding-left: 3em;
        padding-top: 1.2em;
        border-bottom: 1px solid $grayish-blue;
        @include max-screen(medium) {
          padding-left: 1em;
        }
        .quantity-header {
          font-weight: 700;
          font-size: 22px;
          line-height: 40px;
          color: $primary-blue;
          font-family: $font-family-primary;
        }
        .quantity-value {
          font-weight: 400;
          font-size: 18px;
          color: $primary-blue;
          font-family: $font-family-primary;
        }
      }
      .order-quantity-with-border {
        margin: 0;
        width: -webkit-fill-available;
        padding-left: 3em;
        padding-top: 1.2em;
        border: 1px solid $grayish-blue;
        border-right: none !important;
        @include max-screen(medium) {
          padding-left: 1.5em;
        }
        .quantity-header {
          font-weight: 700;
          font-size: 22px;
          line-height: 40px;
          color: $primary-blue;
          font-family: $font-family-primary;
        }
        .quantity-value {
          font-weight: 400;
          font-size: 18px;
          color: $primary-blue;
          font-family: $font-family-primary;
        }
      }
      .line-item-details {
        display: flex;
      }

      .orderDetailsGridWithBorder {
        margin: 0;
        width: -webkit-fill-available;
        padding-left: 3.9em;
        padding-top: 1.2em;
        padding-bottom: 1.2em;
        border: 1px solid $grayish-blue;
        border-left: none !important;
        @include max-screen(medium) {
          padding-left: 2em;
        }
        .accordionBodySegment {
          width: 90%;
          .productTitle {
            font-family: $font-family-primary;
            color: $accent-blue;
            font-weight: 700;
            font-size: 24px;
          }
          .productCode {
            font-weight: 400;
            font-size: 14px;
            font-family: $font-family-primary;
            color: $primary-blue;
          }
          .product-header {
            font-weight: 700;
            font-size: 14px;
            font-family: $font-family-primary;
            color: $black;
          }
          .product-value {
            font-weight: 400;
            font-size: 14px;
            font-family: $font-family-primary;
            color: $black;
          }
        }
      }
      .orderDetailsActions {
        .buttonActionSection {
          float: right;
          padding-right: 28px;
          display: flex;
          flex-direction: column;
          gap: 1.5em;
          width: 90%;
          margin-left: 0 !important;
          white-space: nowrap;
          .buttonBorderBlue {
            background: $white;
            color: $primary-blue;
            border: 3px solid $primary-blue;
            border-radius: 3rem;
            text-decoration: none;
            width: 93%;
            margin-left: 0 !important;
            padding: 1.2rem;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 0.5rem;
            font-weight: bold;
            font-size: 1.8rem;
          }
          .buttonBorderBlue:hover {
            background: $primary-blue;
            color: $white !important;
          }
          .buttonFillBlue {
            background: $primary-blue;
            color: $white;
            border: 3px solid $primary-blue;
            border-radius: 3rem;
            text-decoration: none;
            width: 100%;
            margin-left: 0 !important;
            padding: 1.2rem;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 0.5rem;
            font-weight: bold;
            font-size: 1.8rem;
          }
          .buttonFillBlue:hover {
            background: $white;
            color: $primary-blue;
            border: 3px solid $primary-blue;
          }
        }
      }
      .refund-header {
        font-weight: 700;
        font-size: 22px;
        font-family: $font-family-primary;
        line-height: 2.5;
      }
      .partial-refund-header {
        font-weight: 700;
        font-size: 18px;
        font-family: $font-family-primary;
        color: $primary-blue;
      }
      .partial-refund-status {
        font-weight: 400;
        font-size: 18px;
        font-family: $font-family-primary;
        color: $primary-blue;
      }
    }
  }

  .accordionOrderHistory::before {
    left: unset;
  }
  .order-history-filter-dropdown {
    font-size: 16px;
    background-color: $white;
    border-radius: 5px;
    padding: 0.3em !important;
    .MuiNativeSelect-icon {
      padding-right: 5px;
      padding-top: 2px;
      font-size: 18px;
    }
    .MuiNativeSelect-select:focus {
      background-color: $white;
    }
    .MuiSelect-selectMenu {
      padding-top: 0 !important;
      padding-bottom: 0 !important;
    }
    select {
      padding-left: 0.2em;
    }
    .MuiSelect-icon {
      font-size: 19px !important;
      padding: 5px;
      top: 6px !important;
    }
    .MuiListItemText-root {
      span {
        font-size: 16px !important;
        font-family: $font-family-primary;
      }
    }
  }
  .filter-multiselect {
    width: 100%;
    margin: auto 0 !important;
    font-size: 16px !important;
    background-color: $white;
    border-radius: 5px;
    padding: 5px;
    .MuiSelect-icon {
      font-size: 20px;
      padding: 0 5px 5px !important;
    }
    .MuiSelect-select:focus {
      background-color: transparent !important;
    }
    .status-label {
      font-size: 16px;
      color: $cool-grey-4;
      font-family: $font-family-primary;
    }
  }
  .filter-autocomplete {
    display: flex;
    width: 100%;
    input {
      font-size: 16px;
      margin-right: 1.5em;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .MuiAutocomplete-endAdornment {
      top: 3px !important;
    }
    .MuiTextField-root {
      justify-content: center;
      .MuiAutocomplete-inputRoot {
        background-color: $white;
        border-radius: 5px;
        padding: 0.3em !important;
      }
    }
  }
  .clear-filter-div {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: 2.5em 3.5em 0;
    font-size: 16px;
    a {
      justify-content: center;
      cursor: pointer;
    }
    @include max-screen(medium) {
      margin: 2.5em 1.5em 0 !important;
    }
  }
  .search-keyword {
    display: flex;
    flex-direction: row;
    background-color: $white;
    margin: auto 0;
    width: 100%;
    border-radius: 5px;
    justify-content: space-between;
    padding: 0.2em 0.5em;
    .input-div {
      width: 70%;
      margin: 0.3em 0.5em 0.3em 0em;
    }
    .icons-div {
      display: flex;
      width: 20%;
      justify-content: end;
    }
    .input-search {
      font-family: $font-family-primary;
      border: none !important;
      width: 100%;
      line-height: 24px;
      font-size: 16px !important;
    }
    .input-search::placeholder {
      color: $cool-grey-4 !important;
      font-size: 15px !important;
    }
    .filter-search {
      color: $accent-blue;
      font-size: 20px;
    }
    .show-filter-clear {
      display: none;
    }
    .MuiIconButton-root {
      padding: 0.2em !important;
    }
  }
  .order-history-details-div {
    padding: 0 2.6em !important;
    @include max-screen(medium) {
      padding-left: 1em;
    }
    @include max-screen(xmedium) {
      padding: 0 1em !important;
    }
  }
  .no-results-text {
    display: flex;
    justify-content: center;
    color: $primary-blue;
    font-size: 3rem;
    padding: 1em 0 1.5em;
    font-weight: 700;
  }
  .interval-dropdown-div {
    .MuiFormControl-root {
      width: 100%;
    }
  }
}
div:has(ul > li.status-select-menu),
div:has(ul > li.interval-select-menu) {
  min-width: fit-content !important;
  font-family: $font-family-primary !important;
  span {
    font-size: 16px !important;
  }
  .MuiSvgIcon-root {
    width: 0.6em !important;
  }
  .MuiListItem-root {
    padding: 0em 1em !important;
  }
  .MuiIconButton-root {
    padding: 0em 0.5em !important;
  }
  .MuiListItemText-root {
    margin: 0em 0.2em !important;
    .MuiTypography-root {
      font-family: $font-family-primary;
    }
  }
  .Mui-selected {
    :hover {
      color: $white !important;
    }
    .Mui-checked {
      color: $primary-blue !important;
    }
  }
  option {
    padding: 0em 1em;
    cursor: pointer;
    font-size: 16px !important;
  }
  .interval-select-menu:hover,
  .status-select-menu:hover {
    color: $white;
    background-color: $primary-blue;
    .MuiCheckbox-root {
      color: $white !important;
    }
  }
  .menu-checkbox:hover {
    color: $white;
    background-color: $primary-blue;
  }
  .Mui-checked {
    color: inherit !important;
    :hover {
      color: $white !important;
    }
  }
}

div:has(ul#order-number-filter-popup),
div:has(ul#product-title-filter-popup) {
  font-size: 14px !important;
  overflow: hidden !important;
  .MuiAutocomplete-option[data-focus='true'] {
    background-color: $primary-blue !important;
    color: $white !important;
  }
}
