@import './_variables.scss';

@mixin outline-accessability {
  outline: 2px solid $accent-blue;
  outline-offset: 2px;
}

:focus {
  @include outline-accessability();
}

input,
textarea,
button {
  :focus {
    @include outline-accessability();
  }
}
