@import '../../assets/styles/mixins.scss';
@import '../../assets/styles/_variables.scss';
@import '../../assets/styles/_typography.scss';

.helpful-tips-section {
  margin: 2em;
  padding: 0.5em;
  font-family: $font-family-primary;

  @include max-screen(x-medium) {
    margin: 1em;
  }

  @include max-screen(small) {
    margin: 0.5em;
  }

  @include range-screen(tiny, medium) {
    li img,
    ol img {
      width: 100% !important;
      height: auto !important;
      max-width: 100% !important;
    }
  }

  .MuiAccordion-root {
    width: fit-content;
    width: initial;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0) !important;
  }
  .MuiAccordionSummary-content.Mui-expanded {
    margin: 0 0;
  }
  .MuiAccordion-root.Mui-expanded {
    margin-bottom: 0;
    margin-top: 0;
  }
  .MuiAccordionDetails-root {
    flex-direction: column !important;
    .MuiTypography-root {
      font-family: $font-family-primary;
    }
    .section-content {
      border: 1px solid lightgrey;
      padding: 1em;
    }
  }
  .MuiAccordionSummary-content {
    .MuiTypography-root {
      font-family: $font-family-primary;
      font-style: normal;
      font-weight: 700;
      font-size: 1.3em;
      color: $primary-blue;
    }
  }
  .MuiAccordion-root::before {
    top: unset !important;
    right: unset !important;
    border: none !important;
  }
  .accordionOrderHistory {
    border-bottom: 2px solid $lily-white;
  }
  .accordionOrderHistory:last-child {
    border-bottom: none !important;
  }
}
