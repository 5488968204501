@import '../../assets/styles/_mixins.scss';
@import '../../assets/styles/_variables.scss';
@import '../../assets/styles/_typography.scss';
.reviewCartOrder {
  hr {
    margin-left: 5%;
    margin-right: 0%;
    margin-top: 3%;
    margin-bottom: 3%;
  }
  .checkoutPageTitle {
    font-weight: 700;
    font-size: 36px;
    line-height: 43px;
    color: $primary-blue;
    font-family: $font-family-primary;
    margin: 0 !important;
    padding: 1.5em 1.5em !important;
    font-family: $font-family-primary;
    span {
      font-weight: normal;
    }
  }
  .reviewOrderSection {
    display: flex;
    flex-direction: row;
    .reviewOrderSNo {
      font-style: normal;
      font-weight: 700;
      font-size: 2.2rem;
      line-height: 0.3;
      padding: 1.5rem;
      color: $white;
      background: $primary-blue;
      aspect-ratio: 1/1;
      border-radius: 50%;
      margin-top: 0 !important;
      display: flex;
      align-items: center;
    }
    .reviewOrderTitle {
      margin-top: 0 !important;
      font-family: $font-family-primary;
      p {
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 41px;
        color: $primary-blue;
        font-family: $font-family-primary;
        vertical-align: middle;
        margin-bottom: 0 !important;
        margin-top: 0 !important;
        white-space: nowrap;
      }
    }
    .reviewOrderEdit {
      margin: 0;
      margin-left: auto;
      white-space: nowrap;
      svg {
        color: $accent-blue;
        vertical-align: -2px;
      }
      a {
        text-decoration: underline;
        text-decoration: underline;
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 42px;
        text-decoration-line: underline;
        color: $accent-blue;
        margin-left: 5px;
      }
    }
  }
  .reviewOrderDetailsTop {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: baseline;
    .productTitle {
      font-weight: 700;
      font-size: 24px;
      line-height: 29px;
      -webkit-text-decoration-line: underline;
      text-decoration-line: underline;
      color: $accent-blue;
      font-family: $font-family-primary;
      margin: 0 !important;
      padding-right: 0.5em;
      padding-left: 2.6em;
      padding-top: 1.5rem;
    }
    .productPrice {
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 29px;
      text-align: right;
      color: $black;
      font-family: $font-family-primary;
      margin-top: 0 !important;
    }
  }
  .reviewOrderDetailsBottom {
    display: flex;
    flex-direction: row;
    padding-top: 1rem;
    gap: 2rem;
    padding-bottom: 1rem;
    padding-left: 6.2rem;
    .formatSection,
    .quantitySection,
    .emailSection,
    .languageSection {
      .quantity-error {
        color: red;
        margin: 2px 0 0 5px;
      }
      .quantity-header-div {
        display: flex;
        min-width: 15rem !important;
        p {
          min-width: unset !important;
        }
      }
      p {
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 22px;
        color: $primary-blue;
        margin: 0 !important;
        min-width: 15rem;
      }
      .productDetailInnerTitleText {
        color: darkslategrey;
        font-weight: normal;
        display: flex;
        margin-top: 0 !important;
        margin-bottom: 0 !important;
        word-break: break-all;
        .highlight {
          background: #ff9100;
        }
        .read-more-state {
          display: none;
        }
        .read-more-state:checked ~ .read-more-wrap .read-more-target {
          opacity: 1;
          font-size: inherit;
          max-height: 999em;
        }

        .read-more-state ~ .read-more-wrap > .read-more-trigger:before {
          content: '...see more';
        }

        .read-more-state:checked ~ .read-more-wrap > .read-more-trigger:before {
          content: '...see less';
        }
        .read-more-trigger {
          cursor: pointer;
          display: inline-block;
          padding: 0 0.5em;
          color: $accent-blue;
          font-size: 0.9em;
          line-height: 2;
        }
        .read-more-wrap {
          opacity: 1;
          font-size: inherit;
          max-height: 999em;
          .read-more-target {
            opacity: 0;
            max-height: 0;
            font-size: 0;
            transition: 0.25s ease;
          }
        }
        button {
          background: none;
          color: inherit;
          border: none;
          padding: 0;
          font: inherit;
          cursor: pointer;
          outline: inherit;
          font-weight: normal;
          margin-bottom: 10px;
          text-decoration: underline;
          color: $accent-blue;
          font-size: 1.5rem;
        }
        .productDetailEmails {
          color: darkslategrey;
          font-weight: normal;
          padding-right: 0.5em;
        }
      }
      .productEmailExpanded {
        display: flex;
        flex-direction: row;
      }
      .productEmailExpanded:last-child {
        span {
          display: none !important;
        }
      }
    }
  }
  .digitalDeliveryArea {
    p {
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 22px;
      color: $black;
      font-family: $font-family-primary;
      padding-left: 6em;
    }
  }

  .reviewItemOne {
    .standardOptInDiv {
      padding: 1em 6em;
      padding-right: 0em;
      padding-left: 3.5em !important;
    }
    .optInNotifications {
      padding: 1rem;
      background: $lily-white;
      border: 1px solid $light-grey;
      display: flex;
      align-items: center;
      .optinnotificationtext {
        font-family: $font-family-primary;
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 22px;
        color: $primary-blue;

        input {
          margin-right: 10px;
          width: 18px !important;
          height: 20px !important;
        }
      }
      .tooltip {
        .MuiButton-root {
          padding: 0 !important;
          min-width: 24px;
        }
        .MuiSvgIcon-root {
          font-size: 2.125rem;
        }
      }
    }
  }
}
