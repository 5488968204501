@import '../../assets/styles/_variables.scss';
@import '../../assets/styles/_typography.scss';

.uploadtaxexempt {
  .taxexempt {
    display: flex;
    input {
      height: 18px;
      width: 18px;
    }
  }
  .filenamediv {
    color: $accent-blue;
    padding-left: 1em;
    padding-top: 0.3em;
    .filenamespan {
      text-decoration: underline;
      color: $accent-blue;
    }
    .fafileicon {
      position: relative;
      top: 2px;
      padding-right: 5px;
    }
    .facloseicon {
      position: relative;
      top: 3px;
      padding-left: 10px;
      font-weight: 400;
      font-size: 15px;
    }
  }
  .tooltip {
    padding: 0 !important;
    margin: 0 !important;
    height: fit-content;
    .tooltipArea {
      margin-top: -1px;
      margin-left: 3px;
      .MuiButton-root {
        padding-top: 0.4em;
        .MuiButton-label {
          .MuiSvgIcon-root {
            font-size: 2.125rem;
          }
        }
      }
    }
  }
  .buttondiv {
    .MuiButton-root {
      background: none;
      background-color: $white !important;
      color: inherit;
      margin-top: 1%;
      margin-left: 0.3%;
      border: 3px solid $primary-blue !important;
      padding: 0.3em 3em 0.3em 3em;
      font: inherit;
      cursor: pointer;
      outline: inherit;
      .MuiButton-label {
        font-family: $font-family-primary;
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 22px;
        color: $primary-blue;
      }
    }
  }
}
