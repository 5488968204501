@import 'mixins.scss';
@import '_variables.scss';
@import '_typography.scss';

.cta {
  color: $white;
  background-color: $primary-blue;
  padding: 12px 32px 13px 32px;
  // display: inline-block;
  width: 100%;
  text-align: center;
  font-size: 1.8rem;
  min-width: 64px;
  box-sizing: border-box;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14),
    0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-family: 'Catamaran', sans-serif;
  font-weight: 500;
  line-height: 1.75;
  border-radius: 25px;
  text-transform: inherit;
  text-decoration: none;

  &.secondary {
    background-color: $white;
    color: $primary-blue;

    :hover,
    :focus {
      background-color: $accent-blue;
      color: $white;
    }
  }

  &.disabled {
    background-color: $iron-grey;
    color: $silver-foil;

    :hover,
    :focus {
      background-color: $iron-grey;
      color: $silver-foil;
      outline: none;
      outline-offset: 0;
      cursor: default;
    }
  }
}
.cta:hover {
  background-color: $accent-blue;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14),
    0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.cta:focus {
  background-color: #d5d5d5;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14),
    0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.ws-white-button-no-border {
  background-color: white !important;
  color: $primary-blue !important;
  margin: 0 !important;
  padding: 0.5em 2em !important;
  border: 3px solid white !important;
  border-radius: 34px !important;
  font-family: $font-family-primary;
  font-weight: 700 !important;
  font-size: 1em;
  min-height: 3em;
  cursor: pointer;
  transition: 0.3s;
}

.ws-white-button {
  background-color: white !important;
  color: $primary-blue !important;
  margin: 0 !important;
  padding: 0.5em 2em !important;
  border: 3px solid $primary-blue !important;
  border-radius: 34px !important;
  font-family: $font-family-primary;
  font-weight: 700 !important;
  font-size: 1em;
  min-height: 3em;
  cursor: pointer;
  transition: 0.3s;
}

.ws-white-button-min-width {
  min-width: 10em !important;
}

.ws-white-button:hover,
.ws-white-button-no-border:hover {
  background-color: $primary-blue !important;
  color: white !important;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14),
    0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.ws-white-button:focus,
.ws-white-button-no-border:focus {
  background-color: #d5d5d5;
  color: $primary-blue;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14),
    0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.ws-primary-button-no-border {
  background-color: $primary-blue !important;
  color: white !important;
  margin: 0 !important;
  padding: 0.5em 2em !important;
  border: 3px solid $primary-blue !important;
  border-radius: 34px !important;
  font-family: $font-family-primary;
  font-weight: 700 !important;
  font-size: 1em;
  min-height: 3em;
  cursor: pointer;
  transition: 0.3s;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14),
    0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.ws-primary-button {
  background-color: $primary-blue !important;
  color: white !important;
  margin: 0 !important;
  padding: 0.5em 2em !important;
  border: 3px solid white !important;
  border-radius: 34px !important;
  font-family: $font-family-primary;
  font-weight: 700 !important;
  font-size: 1em;
  min-height: 3em;
  cursor: pointer;
  transition: 0.3s;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14),
    0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.ws-primary-button:hover,
.ws-primary-button-no-border:hover {
  background-color: white !important;
  border: 3px solid $primary-blue;
  color: $primary-blue !important;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14),
    0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.ws-primary-button:focus,
.ws-primary-button-no-border:focus {
  background-color: #353535;
  color: white;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14),
    0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.quantity-picker {
  border-radius: 0;
  .quantity-display {
    padding: 0;
  }

  .quantity-modifier {
    background: $primary-blue !important;
    color: $white !important;
    font-weight: 400;
    aspect-ratio: 1/1;
  }
}

.salesPriceItem {
  display: flex;
  vertical-align: middle;
  align-items: center;
  justify-content: space-between;
  @include range-screen(tiny, medium) {
    vertical-align:middle;
    align-items: flex-start;
    gap: 1em;
    padding-bottom: 0.75em;
    padding-top: 0.75em;
    border-bottom: 1px solid $lightgrey;
    flex-direction: column;
  }
  .unitPrice {
    font-style: normal;
    font-weight: 700;
    @include font-size(2.25);
    color: $primary-blue;
    margin: 0 !important;
    font-weight: 700;
    font-size: 24px;
    color: $primary-blue;
    width: 25%;
  }
  .editionType {
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    color: $primary-blue;
    min-width: 55% !important;
    margin: 0 !important;
    width: 55%;
    align-items: center;
    @include range-screen(tiny, medium) {
      width: fit-content;
    }
    @include min-screen(xmedium) {
      display: flex;
    }
    .edition {
      padding-left: 1em;
      @include font-size(1.75);
      font-weight: 400;
      font-size: 18px;
      line-height: 22px;
    }
  }
  .language {
    font-style: normal;
    font-weight: 400;
    @include font-size(1.75);
    line-height: 22px;
    color: $primary-blue;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    color: $primary-blue;
    width: 20%;
    text-align: right;
    @include range-screen(tiny, medium) {
      text-align: left;
      margin: 0;
    }
  }
}
