.statistics-store-mobile-div {
  border-top: 1px solid grey;
  padding: 1em;
  font-size: 1.5rem !important;
  color: black !important;
  text-decoration: none !important;
  a {
    color: black !important;
    text-decoration: none !important;
  }
}
.statistics-store-div {
  padding-left: 0 !important;
}
