@import '_variables.scss';

html {
  font-size: 10px;
}

body {
  background-color: $white;
  margin: 0;
  padding: 0;
  &::backdrop {
    background-color: #fafafa;
  }
}

input,
button,
a {
  outline: none !important;
  font-family: $font-family-primary;
}

input[type='email']:invalid {
  border: 1px solid red;
}

.justify-space-between {
  justify-content: space-between;
}

.center-window {
  display: flex;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
}

@media print {
  body {
    background-color: $white;
  }
}
