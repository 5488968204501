@import '../../assets/styles/_mixins.scss';
@import '../../assets/styles/_variables.scss';
@import '../../assets/styles/_typography.scss';

.centerContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50vh;
  margin: 1% 0em;
  .orderPendingSection {
    display: block;
    padding: 0 1em 0 1em;
    .orderPendingHeader {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .orderSummaryEditCart {
        line-height: 3.3em;
        svg {
          font-size: 18px;
          color: $accent-blue;
          font-weight: 400;
          vertical-align: -0.1em;
        }
        #OrderHistory {
          color: $accent-blue;
          font-weight: 700;
          text-decoration: none !important;
          padding-left: 0.25em;
        }
      }
      h1 {
        padding: 0 !important;
        margin: 0 !important;
        color: $alert-red;
      }
    }
    .orderPendingContent {
      .orderPendingRichText {
        a {
          color: $accent-blue;
          font-weight: 700;
          text-decoration: none !important;
        }
      }
    }
  }
}
