@import '../../assets/styles/mixins.scss';
@import '../../assets/styles/_variables.scss';
@import '../../assets/styles/_typography.scss';

.MuiMenu-list {
  .Mui-selected {
    font-size: 18px;
  }
  .MuiListItem-gutters,
  .MuiInput-input {
    font-size: 18px !important;
  }
}

.MuiFormControl-root {
  margin: 0 !important;
}

.thumbnailsection {
  padding: 1em 0em !important;
}
.cartDetailsSection {
  @include range-screen(tiny, medium) {
    padding: 1em 1em;
    padding-top: 0 !important;
  }
  @include min-screen(medium) {
    padding: 1em 3.8em;
    padding-right: 2rem;
    padding-top: 0 !important;
  }
  .title {
    background: $primary-blue;
    color: white;
    text-decoration-line: underline;
    padding: 1em 1.5em;
    font-weight: 700;
  }
  .MuiCardContent-root {
    padding: 0 !important;
    .MuiTypography-root {
      a {
        color: white;
        font-family: $font-family-primary;
        font-size: 24px;
      }
    }
    .optInDiv {
      padding: 1em 1em;
    }
  }
  .MuiGrid-root {
    margin-bottom: 2% !important;
    max-width: 100%;
  }
  .cartPageTitle {
    font-weight: 700;
    font-size: 36px;
    line-height: 43px;
    color: $primary-blue;
    font-family: $font-family-primary;
    margin: 0 !important;
    padding: 1em 0em !important;
    span {
      font-weight: normal;
    }
  }
  .red-title {
    color: $primary-red !important;
  }
}

.cartDetailGrid {
  flex-direction: row;
  align-items: flex-start;
  gap: 0.75em;
  padding: 0rem 1rem;

  @include min-screen(medium) {
    display: flex;
  }
  .productdetailssection {
    padding-left: 1em !important;
    flex: 1;
    .editionSection,
    .publishedSection,
    .languageSection,
    .statusSection {
      font-weight: 700;
      font-size: 18px;
      line-height: 22px;
      color: $primary-blue;
      width: 50%;
      .productInfoFieldTitle {
        margin-bottom: 0.25em !important;
      }
      .productDetailInnerTitleText {
        color: $darkslategrey;
        font-weight: 400;
        font-size: medium;
        margin-top: 0 !important;
      }
    }
    .formatSection {
      font-weight: 700;
      font-size: 18px;
      line-height: 22px;
      color: $primary-blue;
      margin-top: 1.5rem;
      .formatTitleArea {
        display: flex;
        flex-direction: row;
        gap: 0.5em;
        align-items: center;
        .tooltip {
          padding: 0 !important;
          margin: 0 !important;
          height: fit-content;
          margin-left: 0 !important;
          .tooltipArea {
            .MuiButton-root {
              padding-top: 0.4em;
              .MuiButton-label {
                .MuiSvgIcon-root {
                  font-size: 2.5rem;
                }
              }
            }
          }
        }
      }
      .formatDropdown {
        display: flex;
        .MuiNativeSelect-icon {
          top: inherit !important;
        }
      }
      .productInfoFieldTitle {
        margin-top: 0.25em !important;
        margin-bottom: 0.25em !important;
      }
      .productDetailInnerTitleText {
        font-weight: 400;
        color: darkslategrey;
        font-size: medium;
        margin-top: 0 !important;
      }
      .MuiFormControl-root {
        margin: 0 !important;
      }
    }
  }

  .pricedetailssection {
    @include range-screen(tiny, medium) {
      padding-left: 1em !important;
    }
    @include min-screen(medium) {
      padding: 1em 0em !important;
      padding-top: 0.25em !important;
      width: 20%;
    }
    .priceTitle {
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 22px;
      color: $primary-blue;
      font-family: $font-family-primary;
      margin-bottom: 0 !important;
    }
    .unitPrice {
      font-style: normal;
      font-weight: 700;
      font-family: $font-family-primary;
      line-height: 43px;
      color: $primary-blue;
      padding-top: 0.25em !important;
      margin: 0 !important;
      font-weight: 700;
      font-size: 36px;
      padding-bottom: 0.75em;
    }
    .quantity {
      .quantityRemoveSection {
        a {
          font-weight: 700;
          font-size: 14px;
          line-height: 17px;
          text-decoration-line: underline;
          color: $accent-blue;
          cursor: pointer;
        }
      }
    }
  }
}

.optInDiv {
  .optInNotifications {
    .optinnotificationtext {
      .optInCartFullText {
        margin: 0 !important;
      }
      .upgradeTextTitleEdition {
        padding-left: 3px;
        padding-right: 3px;
      }
    }
  }
}
.optInNotifications {
  padding: 1.3em 1em;
  background: #e9ebea;
  border: 1px solid #c7c9c8;
  display: flex;
  align-items: center;
  input {
    width: 24px;
    height: 24px;
    vertical-align: -0.2em;
    margin: 0;
    margin-right: 15px;
  }
  .optinnotificationtext {
    font-family: $font-family-primary;
    line-height: 22px;
    color: $primary-blue;
    padding-right: 1.5em;
    display: flex;
  }
  .OptinLabelRedline {
    font-weight: bold;
    color: $primary-red;
  }
  .tooltip {
    .MuiButton-root {
      padding: 0 !important;
      min-width: 24px;
    }
    .MuiSvgIcon-root {
      font-size: 2.125rem;
    }
  }
}
