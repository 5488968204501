@import '../../assets/styles/_variables.scss';
@import '../../assets/styles/_typography.scss';

.manage-users {
  height: 100% !important;
  margin: 2em 4em;
  font-family: $font-family-primary !important;

  .main-section{
    display: flex;
    flex-direction: column;
    gap: 1em;
    .horizontal-line {
      border: 0.5px solid #c7c9c8;
      width: 100%;
    }
  }
  .consent-section {
    font-family: $font-family-primary;
    display: flex;
    flex-direction: column;

    @include max-screen(medium) {
      width: 100%;
    }

    .consent-heading {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      @include max-screen(small) {
        display: flex;
        flex-direction: column !important;
      }
      h3 {
        margin: 0 !important;
      }
      .consent-toggle{
        padding-top: 0.5em !important;
        display: flex;
        flex-direction: row;
        align-items: center;
      }
    }
  }

  @include max-screen(medium) {
    margin: 2em 2em;
  }

  @include max-screen(small) {
    margin: 1em 1em;
  }

  h1 {
    margin: 0 !important;
    padding-bottom: 0.25em !important;

    @include max-screen(small) {
      font-size: 3rem;
    }
  }

  .user-list-section {
    width: 75%;
    font-family: $font-family-primary;
    display: flex;
    flex-direction: column;

    @include max-screen(medium) {
      width: 100%;
    }

    .user-list-heading {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      @include max-screen(small) {
        display: flex;
        flex-direction: column !important;
      }
      .user-count {
        font-size: 1.75rem;
      }
      .EditLink {
        font-weight: 700;
        svg {
          color: $accent-blue;
          padding-right: 5px;
          padding-bottom: 5px;
          vertical-align: middle;
        }
      }

      h3 {
        margin: 0 !important;

        @include max-screen(small) {
          font-size: 2rem;
        }
      }

      .edit-mode-buttons {
        display: flex;
        gap: 1em;
        .EditLink {
          @include max-screen(small) {
            display: flex;
            align-items: end;
          }
          a {
            @include max-screen(small) {
              font-size: 1.8rem;
            }
          }
          svg{
            @include max-screen(small) {
              font-size: 1.8rem;
            }
          }
        }
      }

      .button-link-section {
        display: flex;
        gap: 1em !important;
        .EditLink {
          a {
            @include max-screen(small) {
              font-size: 1.8rem;
            }
          }
        }
      }
    }

    .users-table {
      padding: 0.5em 0 !important;
      margin-bottom: 1em;
      font-family: $font-family-primary;
    }

    .main-btn-section {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .edit-mode-buttons {
        display: flex;
        gap: 1em;
        .EditLink {
          @include max-screen(small) {
            display: flex;
          }
          a {
            @include max-screen(small) {
              font-size: 1.8rem;
            }
          }
        }
      }
      .button-link-section {
        display: flex;
        gap: 1em !important;
        .EditLink {
          a {
            @include max-screen(small) {
              font-size: 1.8rem;
            }
          }
        }
      }
    }

    .EditLink {
      font-weight: 700;
      svg {
        color: $accent-blue;
        padding-right: 5px;
        padding-bottom: 5px;
        vertical-align: middle;
      }
    }

    .add-user {
      display: flex;
      flex-direction: row;
      min-width: 25vw;
      width: -webkit-fit-content;
      width: -moz-fit-content;
      width: fit-content;
      padding: 16px;
      border: 1px solid lightgray;
      background-color: #f2f2f2;

      @include max-screen(small) {
        width: 90% !important;
      }
    }

    .users-table .MuiTableContainer-root .MuiTableRow-head {
      background: $primary-blue !important;
    }

    .users-table .MuiTableContainer-root .search-thead {
      background: white !important;
    }

    .users-table .MuiTableContainer-root .search-thead th {
      background: white !important;
    }

    .users-table .MuiTableContainer-root .search-thead th input {
      padding: 0.5em 0.5em;
      width: 50%;
      @include max-screen(small) {
        width: 100% !important;
      }
    }

    .users-table .MuiTableContainer-root {
      font-family: $font-family-primary;
      border-radius: 4px !important;
    }

    .users-table,
    .MuiTableContainer-root,
    .MuiTableCell-body,
    .MuiTableCell-root {
      font-family: $font-family-primary;
    }

    .DeleteLink {
      font-weight: 700 !important;
      display: flex;
      align-items: center;
      white-space: nowrap;
    }
    .deleteBtn {
      outline: none;
      color: #cd163f;
      padding: 0 !important;
      font-weight: 700 !important;
      box-shadow: none;
    }

    .deleteBtn:hover,
    .deleteBtn:active,
    .deleteBtn:focus {
      box-shadow: none;
      background: none !important;
    }

    .delete-icon {
      color: #cd163f;
      border: none;
      border-radius: 3px;
      cursor: pointer;
      margin: 0 !important;
      margin-right: 0.25em !important;
    }

    .grey-row {
      background-color: #f2f2f2;
    }

    .enabled-switch {
      color: #228747;
    }

    .enabled-switch.Mui-checked + .MuiSwitch-track {
      background-color: #228747;
    }

    .disabled-switch {
      opacity: 1 !important;
      pointer-events: none;
      color: #bdbdbd !important;
    }

    .users-table .MuiTableContainer-root .MuiTable-root .MuiTableHead-root {
      font-family: $font-family-primary;
    }

    .users-table .MuiTableContainer-root .MuiTable-root .MuiTableBody-root .MuiTableCell-body{
      word-break: break-all !important;
    }

    .heading {
      font-size: 1.2rem;
      margin-bottom: 10px;
    }

    table {
      width: 100%;
      border-collapse: collapse;

      th {
        background-color: $primary-blue;
        color: #fff;
        padding: 5px;
      }

      td {
        padding: 5px;
        border-bottom: 1px solid #ccc;
      }

      .delete-user-btn {
        background: none;
        border: none;
        cursor: pointer;
        color: red;
        font-size: 1rem;
      }
    }

    .add-user {
      display: flex;
      margin-top: 10px;
      flex-direction: column;

      .user-add-area {
        display: flex;

        @include max-screen(small) {
          width: fit-content !important;
          display: flex;
          flex-direction: column;
          gap: 0.5em;
        }
      }
      input[type='text'] {
        flex-grow: 1;
        padding: 5px;
        border-radius: 5px;
        border: 1px solid #ccc;
        width: 16vw;
        font-size: 1.5rem;
        padding: 0.5rem 1rem;

        @include max-screen(small) {
          width: 100% !important;
        }
      }

      label {
        margin-left: 10px;
        font-size: 0.9rem;
      }

      .add-user-btn {
        background-color: $primary-blue;
        color: #fff;
        border: none;
        border-radius: 5px;
        padding: 5px 10px;
        margin-left: 10px;
        cursor: pointer;
        transition: background-color 0.3s;
        display: flex;
        align-items: center;

        @include max-screen(small) {
          width: fit-content !important;
        }

        .icon {
          margin-right: 0.5em;
          margin-right: 0.5em;
          background-color: #17457a;
          color: white;
          border: none;
          border-radius: 3px;
          cursor: pointer;
        }
        .btn-text {
          background-color: #17457a;
          color: white;
          border: none;
          border-radius: 3px;
          cursor: pointer;
          font-size: 1.5rem;
        }
      }

      .error-message {
        color: red;
        font-size: 1.5rem;
        margin: 0 !important;
      }
    }
  }
}
