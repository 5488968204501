@import '../../assets/styles/_mixins.scss';
@import '../../assets/styles/_variables.scss';
@import '../../assets/styles/_typography.scss';

.orderSummarySticky {
  position: sticky;
  position: -webkit-sticky;
  top: 0;
}
.salesTax {
  flex-wrap: wrap;
  justify-content: space-between;
}

.salesTaxPending {
  flex-wrap: wrap;
  display: flex;
  justify-content: space-between;
}

.orderSummaryCheckout {
  padding: 0em 2em 2em;
  padding-right: 3.8em !important;
  padding-left: 0 !important;
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  @include range-screen(tiny, medium) {
    padding: 0em 1.5em !important;
  }
  h1 {
    margin: 0 !important;
    padding-top: 1em;
    font-family: $font-family-primary;
  }
  .orderSummaryEditCart {
    padding-top: 1em;
    padding-bottom: 0.5em;
    font-weight: 700;
    font-family: $font-family-primary;
    display: flex;
    svg {
      color: $accent-blue;
      font-size: 2.5rem;
    }
    a {
      vertical-align: super;
      padding-left: 0.5em;
    }
  }
  .orderPriceDetails {
    background: $primary-blue;

    p {
      margin: 2rem;
    }

    .orderSubTotal {
      display: flex;
      font-family: $font-family-primary;
      border-bottom: 1px solid $white;
      justify-content: space-between;
      .orderSubTotalDetail {
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 22px;
        color: $white;
        font-family: $font-family-primary;
      }
    }
    .orderSalesTax {
      display: flex;
      justify-content: space-between;
      font-family: $font-family-primary;
      border-bottom: 1px solid $white;
      .orderSalesTaxDetail {
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 22px;
        color: $white;
        font-family: $font-family-primary;
      }
    }
    .taxExemptBorder {
      display: flex;
      justify-content: space-between;
    }
    .orderSalesTaxNotUS {
      display: flex;
      justify-content: space-between;
      font-family: $font-family-primary;
      .orderSalesTaxDetailNotUS {
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 22px;
        color: $white;
        font-family: $font-family-primary;
      }
      .orderSalesVerifyTaxExempt {
        display: flex;
        width: max-content;
        font-size: 1.6rem;
        margin-left: 2rem !important;
        margin-bottom: 2rem !important;
        padding: 0 !important;
        .tooltip {
          padding-left: 0.25em;
        }
      }
      .orderSalesTaxNotUSTaxExempt {
        color: $white;
        margin: 0 !important;
      }
      #AccountInfo {
        color: $white;
        padding-left: 0.25em;
      }
      .tooltip {
        .MuiButton-root {
          padding: 0 !important;
          margin: 0 !important;
          .MuiButton-label {
            padding: 0 !important;
            margin: 0 !important;
            .MuiSvgIcon-root {
              font-size: 2.125rem;
            }
          }
        }
      }
    }
    .orderShippingAndHandling {
      display: flex;
      justify-content: space-between;
      font-family: $font-family-primary;
      border-bottom: 1px solid $white;
      .orderShippingDetail {
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 22px;
        color: $white;
        font-family: $font-family-primary;
      }
    }
    .orderMemberDiscount {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      color: $white;
      color: $primary-red;
      background: $lily-white;
      font-family: $font-family-primary;
      .tooltip {
        .MuiButton-root .MuiSvgIcon-root {
          font-weight: 900;
          font-size: 11.25px;
          font-size: 2.5rem;
          line-height: 16.875px;
          line-height: 18px;
          fill: $accent-blue !important;
        }
      }
      .orderMemberDiscountTitleSection {
        display: flex;
        align-items: center;
        p {
          font-weight: 700;
          font-family: $font-family-primary;
        }
        .MuiCheckbox-root {
          .MuiIconButton-label {
            input:hover {
              -webkit-box-shadow: none;
              -moz-box-shadow: none;
              box-shadow: none;
            }
          }
        }
      }
    }
    .orderSummaryAlign {
      justify-content: space-between;
    }
    .orderTotal {
      font-style: normal;
      font-weight: 700;
      font-size: 36px;
      line-height: 43px;
      color: $white;
      justify-content: space-between;
      font-family: $font-family-primary;
      border-top: 1px solid white;
      .orderTotalSection {
        text-align: center;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .MuiButton-containedSecondary {
          min-width: 85%;
          color: $white !important;
          background-color: $primary-blue !important;
          font-family: $font-family-primary;
          margin-top: 5%;
          border: 3px solid;
          border: 3px solid $white;
          border-radius: 34px;
          .MuiButton-label {
            a {
              text-decoration: none !important;
              color: $white !important;
              font-weight: 700;
            }
          }
        }
        .checkoutButton {
          background: $white !important;
          .MuiButton-label {
            a {
              text-decoration: none !important;
              color: $primary-blue !important;
            }
          }
        }
      }
    }
    .orderAgreementSection {
      display: flex;
      flex-direction: row;
      padding: 1em 1.5em;
      grid-gap: 1em;
      gap: 1em;
      color: $black;
      background: $lily-white;
      font-family: $font-family-primary;
      p {
        margin: 0;
      }
      .orderAgreementText {
        margin: 0;
      }
      .orderAgreementSectionTitleSection {
        p {
          margin: 0;
        }
        .MuiButtonBase-root {
          padding: 0;
        }
      }
      .orderAgreementTitleSection {
        padding-top: 1.5em;
      }
      .tooltip {
        .MuiButton-root {
          padding: 0 !important;
          margin: 0 !important;
          .MuiButton-label {
            padding: 0 !important;
            margin: 0 !important;
            .MuiSvgIcon-root {
              font-size: 2.125rem;
            }
          }
        }
      }
    }
  }
  .placeOrderButton {
    padding: 1em 0em;
    .MuiButton-containedSecondary {
      width: 100%;
      background-color: $primary-blue;
      .MuiButton-label {
        a {
          color: $white;
          text-decoration: none;
          font-family: $font-family-primary;
          font-style: normal;
          font-weight: 700;
        }
      }
    }
  }
  .checkoutDisclaimerSection {
    p {
      padding: 0em 0.75em;
      font-size: 1.5rem;
      span {
        font-weight: bold;
      }
    }
  }
}
