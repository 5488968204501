@import '../../assets/styles/_mixins.scss';
@import '../../assets/styles/_variables.scss';
@import '../../assets/styles/_typography.scss';

.subscriptionstandardoption {
  display: flex;
  background-color: $primary-blue;
  flex-direction: column;
  .button-section-left-margin {
    width: $leftTabWidthWithoutMargin;
    @include range-screen(tiny, xmedium) {
      display: none;
    }
  }
  .subscription-standard-section {
    display: flex;
    .subscription-section {
      display: flex;
      flex-direction: column;
    }
  }

  .download-button-section {
    float: left;
    display: flex;
    flex-wrap: wrap;
    margin: 0.5em 0em 2em 0em;
    padding: 0em 4em 0em 3.5em;
    @include min-screen(xmedium) {
      display: flex;
    }
  
    .ws-white-button {
      margin-right: 1em !important;
      white-space: nowrap;
    }
    .ws-white-button:disabled {
      background-color: $cool-grey-8 !important;
      border: 1px solid $cool-grey-4 !important;
      color: $lightgrey !important;
      pointer-events: none;
    }
    .download-error {
      color: white;
    }
  }
  .unsubscribed-note {
    margin: 0em 0em 2em 0em;
    padding: 0em 4em 0em 3.5em;
    font-weight: 700;
    color: $white;
    a {
      color: $white;
    }
    p {
      margin: 0 !important;
      font-weight: 700;
    }
  }
}
