@import '../../assets/styles/_mixins.scss';
@import '../../assets/styles/_variables.scss';

.checkout-details-main {
  p {
    margin-left: 2rem;
  }
  @include range-screen(tiny, medium) {
    padding: 0em 1.5em;
  }
  @include min-screen(medium) {
    padding: 0em 2em 0em 3.8em;
    width: 67%;
  }
}
