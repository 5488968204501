@import '../../assets/styles/mixins';
@import '../../assets/styles/variables';
@import '../../assets/styles/typography';

.subscriptionglobalsearchfield {
  .no-border-box {
    fieldset {
      border-width: 0px !important;
    }
  }

  .searchFieldWrapper {
    padding: 18px 0px;
    background: $lily-white;
    justify-content: center;
    display: flex;

    .MuiAutocomplete-input {
      padding: 9.5px 4px !important;
    }

    .siteSearchField {
      width: 90%;
      border: 0;
      font-size: 22px;
      background-color: white;

      @include max-screen(medium) {
        @include font-size(1.125);
        line-height: 22px;
        color: $light-grey;
        width: 80%;
      }
    }

    .SearchIcon {
      width: 5.5rem;
      aspect-ratio: 1/1;
      background: $primary-red;
      color: $white;
      border: 0;
      padding: 5px 5px 0 5px;

      svg {
        font-size: 20px;
        @include max-screen(large) {
          font-weight: 400;
          @include font-size(1.8);
          line-height: 24px;
          color: $white;
          flex: none;
          order: 0;
          flex-grow: 0;
        }
      }
    }
  }

  .site-search-search-button {
    .siteSearchButton {
      border-radius: 50px;
      border: none;
      background: $primary-blue;
      color: $white;
      font-size: 18px;
      padding: 0.5em 2.5em;
      font-weight: bold;
    }
  }
}
.subscription-search-page {
  .subscriptions-loading {
    width: 100%;
    text-align: center;
    margin: 5em 0;

    h1 {
      margin: 0;
      padding: 0;
    }
  }

  .siteSearchField:focus {
    border: none;
  }

  .search-title {
    align-items: left;
    font-weight: 700;
    font-size: 36px;
    line-height: 43px;
    color: $primary-blue;
    margin-top: 0;
  }

  .search-subtitle {
    align-items: left;
    font-family: 'URW DIN';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 100%;
    color: #333738;
  }

  .facet-heading {
    font-weight: 700;
    font-size: 24px;
    line-height: 25px;
    color: $primary-blue;
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: space-between;

    button {
      background-color: transparent;
      border: 0px;
      color: $primary-blue;
      cursor: pointer;
    }

    button:disabled {
      color: gray;
      cursor: default;
    }
  }

  .facet-subheading {
    font-size: 14px;
    line-height: 25px;
    color: $primary-blue;
    margin-bottom: 1.3em;
  }

  .search-box-container {
    display: flex;
    background-color: white;
    margin: 2%;
    font-size: 1.8rem;
  }

  .search-query-summary {
    p {
      margin-left: 0;
      margin-block-end: 0.5em;
    }
  }

  .column {
    float: left;
    margin: auto;
    padding-bottom: 1rem;
  }

  .outer-box {
    background: #ffffff;
    margin-bottom: 10px;
    .related-programs {
      padding: 20px !important;
      display: flex;
      flex-direction: column;
      .tooltipArea {
        .MuiTooltip-popper,
        .MuiTooltip-popperInteractive {
          will-change: unset !important;
          position: absolute;
          top: 24% !important;
          left: 12% !important;
          z-index: 199 !important;
          @include max-screen(medium) {
            top: 10% !important;
            left: 15% !important;
          }
          @include max-screen(small) {
            top: 3% !important;
            left: 0% !important;
          }
          @include max-screen(tiny) {
            top: 3% !important;
            left: 0% !important;
          }
        }
        .MuiTooltip-tooltip {
          background-color: white;
          color: black;
          border: 1px solid $cool-grey-8;
          min-width: 15rem;
          padding-bottom: 3em;
          box-shadow: 0 0 15px grey;
          width: 75rem;
          max-width: none;

          @include range-screen(tiny, x-small) {
            width: 20rem !important;
            max-width: none !important;
          }
          @include range-screen(xsmall, small) {
            width: 30rem !important;
            max-width: none;
          }
          @include range-screen(small, medium) {
            width: 50rem !important;
            max-width: none;
          }
          @include min-screen(medium) {
            width: 75rem !important;
            max-width: none;
          }
        }
      }
    }
  }

  .box-frame {
    box-sizing: border-box;
    border: 1px solid #c7c9c8;
  }

  .box-result {
    margin: 40px;
  }

  body {
    margin: 0;
    font-family: 'URW DIN', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .result-header {
    align-items: left;
    display: flex;
    justify-content: space-between;
    .title {
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 29px;
      text-decoration-line: underline;
      color: $primary-blue;
    }
  }

  .result-subheader * {
    float: left;
    gap: 5px;
  }

  .result-recent {
    display: flex;
    align-items: center;
    color: $mostrecent-primary-green;
    font-weight: 700;
  }

  .result-previous {
    display: flex;
    align-items: center;
    color: $previous-edition-primary;
    font-weight: 700;
  }

  .result-edition {
    display: flex;
    align-items: center;
    padding-left: 1em;
    b {
      padding-right: 5px;
    }
  }

  .result-status,
  .result-language {
    display: flex;
    align-items: center;
    padding-left: 1em;
    b {
      padding-right: 5px;
    }
  }

  .result-main {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: -webkit-nowrap;
    -webkit-line-clamp: 3;
    display: -webkit-box;
    -webkit-box-pack: end;
    -webkit-box-orient: vertical;
    line-height: 3.6rem;
  }

  .result-list {
    margin: 0;
    padding-left: 2rem;
    padding-top: 0.3em;
  }

  .result-footer * {
    margin-right: 2rem;
    float: left;
    align-items: center;
    font-weight: 700;
  }

  .result-footer a,
  .result-footer a:visited,
  .result-footer a:hover {
    text-decoration: underline !important;
    color: #2b7bb9;
  }

  .result-cart {
    margin-top: auto;
    margin-bottom: auto;
  }
  .result-disclaimer {
    margin-top: auto;
    margin-bottom: auto;
    padding: 1em;
  }

  .result-details-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    background-color: white;
    margin: 20px;
  }

  .result-container {
    gap: 10px;
    justify-content: space-between;
    background-color: white;
    @include min-screen(xmedium) {
      display: flex;
    }
  }

  .result-cart-container {
    background-color: $primary-blue;
    display: flex;
    min-width: 25rem;
    align-items: center;
    justify-content: center;
    @include range-screen(tiny, xmedium) {
      min-height: 15rem;
    }
    @include min-screen(xmedium) {
      min-height: 25rem;
    }
  }

  .non-subscribed-disclaimer-div {
    background-color: $iron-grey;
    display: flex;
    min-width: 25rem;
    align-items: center;
    justify-content: center;
    width: min-content;
    border: 1px solid $cool-grey-5;
    @include range-screen(tiny, xmedium) {
      min-height: 15rem;
      width: unset !important;
    }
    @include min-screen(xmedium) {
      min-height: 25rem;
    }
  }

  .product-price {
    font-size: 36px;
    color: #ffffff;
    width: 100%;
    text-align: center;
    font-weight: 700;
  }
  .share-icon-div {
    margin-left: 1em;
    cursor: pointer;
  }
  .shareicon {
    color: $primary-blue;
    font-size: 16px;
    margin-top: 0.3em;
  }
  .cart-button-div {
    text-align: center;
    padding-top: 0px !important;
    .cart-button {
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 18px;
      font-style: normal;
      font-weight: 700;
      background: $white;
      border: 3px solid $white;
      border-radius: 34px;
      padding: 0.6em 1.6em;
      font-family: $font-family-primary;
    }

    .download-error {
      color: red;
      margin-left: 5px;
    }
  }

  .MuiBackdrop-root {
    background: rgba(100, 100, 100, 0.5) !important;
  }
  .MuiPaper-elevation16 {
    box-shadow: 0px 8px 10px -5px rgb(0 0 0 / 12%), 0px 8px 4px 2px rgb(0 0 0 / 8%),
      0px 6px 8px 5px rgb(0 0 0 / 6%) !important;
  }

  .query-summary {
    display: inline-block;
    font-size: 14px;
    margin-right: auto;
    box-flex: auto;
    -moz-flex: auto;
    -ms-flex: auto;
    flex: auto;
    line-height: 14px;
    padding-left: 2rem;
  }
  .coveo-sort-section {
    display: flex;
    align-items: right;
  }

  .CoveoSort {
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
    box-align: center;
    -moz-align-items: center;
    -ms-align-items: center;
    -o-align-items: center;
    align-items: center;
    -ms-flex-align: center;
    font-size: 14px;
    font-weight: 700;
    padding: 0 5px 6px;
    border-bottom: thin solid #bcc3ca;
    text-transform: uppercase;
    border-bottom: 2px solid #bcc3ca;
  }

  .coveo-accessible-button {
    cursor: pointer;
  }

  .CoveoSort.coveo-selected,
  .CoveoSort.coveo-selected:hover {
    border-bottom: 2px solid #263e55;
  }

  .CoveoSort.coveo-sort-hidden {
    display: none;
  }
  .coveo-results-header {
    -moz-justify-content: space-between;
    -ms-justify-content: space-between;
    -o-justify-content: space-between;
    justify-content: space-between;
    -ms-flex-pack: justify;
    overflow: hidden;
    padding-left: 14px;
    @include min-screen(medium) {
      display: -moz-flex;
      display: -ms-flexbox;
      display: flex;
    }
  }
  .coveo-results-header > :not(:first-child) {
    border: none !important;
  }
  .coveo-results-header > :last-child {
    padding-right: 0;
    border-right: none;
  }
  .coveo-pager-list,
  .results-per-page {
    margin: 0px;
  }
  .sort {
    margin-left: auto;
    margin-right: 15px;
    align-self: center;
  }
  .coveo-pager-list {
    padding-inline-start: 0px;

    button {
      background-color: transparent;
      border: 0px;
      color: $primary-blue;
      cursor: pointer;
      scale: 2;
    }

    button:disabled {
      display: none;
      color: gray;
      cursor: default;
    }
  }
  .coveo-pager-list-item.coveo-active,
  .coveo-pager-list-item:hover {
    color: #cddee9;
    background-color: #1d4f76;
  }
  .coveo-pager-list-item {
    border: thin solid #7e8c9a;
    text-align: center;
    min-width: 15px;
    border-radius: 2px;
    margin-left: 4px;
    margin-right: 4px;
    padding: 4px 8px;
    list-style: none;
    display: inline-block;
    vertical-align: baseline;
    color: #0059b3;
    font-size: 14px;
    cursor: pointer;
    text-decoration-line: underline;
  }
  .coveo-results-per-page-text {
    float: left;
    font-size: 14px;
    padding-top: 3px;
  }
  .results-per-page {
    float: right;
  }
  .CoveoResultLink {
    font-style: normal;
    font-weight: 799;
    font-size: 24px;
    text-decoration-line: underline;
    color: $primary-blue !important;
    font-family: sans-serif;
  }

  .CoveoResultsPerPage {
    margin-right: 5px;
  }

  .did-you-mean-text {
    margin-bottom: 1em;

    b {
      color: $accent-blue;
    }
  }

  .facet-breadcrumb-list {
    font-size: 0.75em;
    list-style-type: none;
    margin: 0 0 60px 0;
    padding: 0;

    li {
      float: left;
      margin-right: 10px;
    }

    button {
      background: transparent;
      color: $accent-blue;
      border: none;
      font-weight: bold;
      padding-right: 1px;
      cursor: pointer;
    }

    button:hover {
      background: transparent;
      color: $primary-blue;
      border: none;
      font-weight: bold;
      padding-right: 1px;
      cursor: pointer;
    }

    span {
      padding: 5px;
    }
  }

  .facet-search-container {
    padding-left: 8px;
    padding-top: 4px;
    cursor: pointer;

    .facet-search-group {
      align-items: center;
      display: flex;
    }

    .facet-search-expand-style {
      margin-right: 8px;
      color: gray;
    }

    .facet-search-text {
      color: $accent-blue;
      font-weight: bold;
    }

    .facet-search-autocomplete-box {
      margin-right: 15px;
      width: auto;
      font-size: 14px !important;

      input {
        font-size: 14px;
      }

      svg {
        width: 0.75em;
        height: 0.75em;
      }
    }
  }

  .facet-box {
    border: 1px solid #c7c9c8;
    margin-bottom: 10px;

    .facet-title {
      color: #2b7bb9;
      font-weight: 700;
      background: #e9ebea;
      margin-bottom: 2px;
      padding: 0.5em 1em !important;
    }

    .facet-list-item {
      margin: 10px;
    }
    .no-facet-list-item {
      margin-left: 16px;
      margin-top: 18px;
      margin-bottom: 18px;
    }

    .show-more-less-wrapper {
      display: flex;
      flex-direction: row;
      align-items: stretch;
      width: 100%;
      column-gap: 2px;
    }

    .show-more-less-wrapper > * {
      flex: 1;
      border: none;
      color: $accent-blue;
      cursor: pointer;
    }
  }
}
