@import '../../assets/styles/_variables.scss';
@import '../../assets/styles/_typography.scss';

.toastSection {
  width: -webkit-fill-available;
  position: fixed;
  top: 100px;
  left: 0;
  width: 100%;
  z-index: 9999;
  @include min-screen(large) {
    top: 140px;
  }

  @include max-screen(small) {
    top: 60px;
  }

  .toastContainer {
    color: white;
    display: flex;
    justify-content: space-between;
    padding: 2.5rem 1.5rem;
    align-items: center;
  }

  .success {
    background-color: #78a22f;
  }

  .error {
    background-color: #cd163f;
  }

  .warning {
    background-color: #ff9100;
  }

  .info {
    background-color: #3676f5;
  }

  .toastContent {
    margin-left: auto;
    margin-right: auto;

    a {
      color: white;
      font-weight: 700;
    }
  }
  .toastClose {
    font-size: 34px;
    margin-right: 2rem;
    cursor: pointer;
    vertical-align: middle;
    text-align: center;
  }
}
