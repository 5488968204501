@import '../../assets/styles/_mixins.scss';
@import '../../assets/styles/_variables.scss';

.subscriptionproductdetails {
  overflow: hidden;
  border-bottom: 3px solid lightgray;

  .scrollsection {
    float: left;
    display: flex;
    white-space: nowrap;
    margin-bottom: 1em;
    padding: 0em 4em;
    @include min-screen(xmedium) {
      display: flex;
    }
    .scrollSectionLeftMargin {
      width: $leftTabWidthWithoutMargin;
      @include range-screen(tiny, xmedium) {
        display: none;
      }
    }
  }

  .scrolllinksection {
    display: flex;

    @include min-screen(xmedium) {
      padding-top: 1em;
    }
    .AddendaSection,
    .PreviousEditionSection,
    .RedlineAvailableSection {
      display: flex;
    }
    .tooltip {
      padding: 0 !important;
      margin: 0 !important;
      height: fit-content;
      .tooltipArea {
        margin-top: -1px;
        margin-left: 3px;
        .MuiButton-root {
          padding-top: 0.4em;
          .MuiButton-label {
            .MuiSvgIcon-root {
              font-size: 2.5rem;
            }
          }
        }
      }
    }
  }

  .scrollinktext {
    color: $white;
    font-size: 18px;
    text-decoration: none;
    padding-right: 0.3em;
    font-weight: 700;
    cursor: pointer;
  }

  .scrolldownicon {
    color: $white;
    font-size: 13px;
    font-family: $font-family-primary;
    padding-right: 3em;
    position: relative;
    top: 9px;
    left: 3px;
    cursor: pointer;
  }

  .productmetadataheader {
    width: 100%;
    height: 100%;
    background: $primary-blue;
    padding-top: 2em;
    display: flex;
    flex-direction: column;
  }

  .productmetadatasection {
    width: 100%;
    float: left;
    background: $primary-blue;
  }

  .faquestionicon {
    font-style: normal;
    font-weight: 100 !important;
    font-size: 11px;
    color: $white;
    padding-bottom: 0.4em;
    padding-left: 0.1em;
    position: relative;
  }

  .descriptiondiv {
    width: 100%;
    height: fit-content;
  }

  .addedndadiv {
    width: 100%;
    height: fit-content;
  }
  .previouseditiondiv {
    width: 100%;
    height: fit-content;
  }
  .clearfix {
    clear: both;
  }
}
