@import '../../assets/styles/_variables.scss';
@import '../../assets/styles/_typography.scss';

.addendaerratasection {
  background: $primary-blue;
  @include range-screen(tiny, small) {
    padding: 2em 1.5em 1em 1.5em;
  }
  @include range-screen(small, medium) {
    padding: 2em 3.5em 2em 3.5em;
  }
  @include min-screen(medium) {
    padding: 2em 3.5em 2em;
  }
  .MuiGrid-grid-md-4 {
    flex-basis: 50% !important;
    max-width: unset !important;
    flex-grow: unset !important;
  }

  .MuiPaper-root {
    background-color: transparent !important;
  }

  .jss3 {
    background-color: transparent !important;
  }

  .MuiGrid-item {
    box-sizing: unset !important;
  }

  .MuiPaper-elevation1 {
    box-shadow: none !important;
  }
  .divs {
    background: $primary-blue;
    width: inherit !important;
    display: flex;
    flex-wrap: wrap;
    .divs-item {
      display: -webkit-box;
    }
  }

  .gridbox {
    background: $primary-blue;
    max-width: 100%;
    width: 100%;
  }

  .addendaerrataheader {
    color: $white;
    font-size: 36px;
    font-family: $font-family-primary;
    font-weight: 700;
    font-style: normal;
    margin-left: 0;
    margin-top: 0;
    margin-bottom: unset;
  }

  .datesection {
    background: $primary-red;
    align-items: center;
    float: left;
    text-align: center;
    margin: 0;
    height: fit-content;
    width: 80px;
    padding: 0.75em;
    padding-bottom: 1em;
  }

  .date {
    font-size: 22px;
    color: $white;
    font-family: $font-family-primary;
    align-items: center;
    font-weight: 400;
    font-size: 36px;
    margin: 0;
    height: fit-content;
    padding: 0 !important;
  }

  .downloadbutton {
    background: $white;
    border-radius: 34px;
    border: 1px solid $white;
    font-family: $font-family-primary;
    padding: 0.5em 0.5em;
    font-weight: 700;
    font-size: 18px;
    color: $primary-blue;
    outline: none;
    cursor: pointer;
  }

  .divdate {
    float: left;
    width: 30%;
  }

  .divdetails {
    background: $primary-blue;
    text-align: left;
    padding-left: 3em;
  }

  .name {
    color: $white;
    font-size: 18px;
    font-weight: 700;
    font-family: $font-family-primary;
  }

  .downloadDiv {
    padding-top: 0.5em;
  }

  .title {
    color: $white;
    font-size: 18px;
    font-weight: 400;
    padding-top: 0.5em;
    padding-bottom: 0.8em;
    font-family: $font-family-primary;
  }

  .griditem {
    box-shadow: none;
    border-radius: none;
    background-color: transparent;
    line-height: normal;
    padding: 1em 0em !important;
    max-width: inherit !important;
  }
}
.addonsection {
  background: $primary-blue;
  font-family: $font-family-primary !important;
  padding: 0em 4em 2em;
  @include range-screen(tiny, small) {
    padding: 0em 1.5em 0.5em 1.5em;
  }
  @include range-screen(small, medium) {
    padding: 0em 3.5em 1em 3.5em;
  }
  @include min-screen(medium) {
    padding: 0em 3.5em 2em;
  }

  .divs {
    width: inherit !important;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 1.5rem;
    row-gap: 0 !important;
    .file-icon {
      color: $white;
      position: relative;
      top: 5px;
    }
  }

  .addon-header {
    color: $white;
    font-size: 36px;
    font-weight: 700;
    font-style: normal;
    margin-left: 0;
    margin-top: 0;
    margin-bottom: unset;
  }

  .title {
    color: $white;
    font-size: 18px;
    font-weight: 400;
    padding-top: 0.5em;
    padding-bottom: 0.8em;
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    flex-wrap: wrap;
    .addon-link {
      color: $white !important;
    }
  }
}
