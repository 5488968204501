@import '../../assets/styles/_mixins.scss';
@import '../../assets/styles/_variables.scss';
@import '../../assets/styles/_typography.scss';

.cookie-overlay {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
}

.cookie {
  .toast-container {
    position: fixed;
    bottom: 0px;
    .toast-header {
      background: $primary-red;
    }
    .toast-body {
      background: $primary-red;
      .description {
        font-weight: 400;
        font-size: 20px;
        text-align: left;
        a,
        a:visited,
        a:hover {
          color: $white !important;
        }
      }
      .MuiButton-containedSecondary {
        background-color: $white;
        padding: 6px 60px;
        margin-top: 2em;
        margin-bottom: 1em;
        height: 40px;
        display: block;

        .MuiButton-label {
          color: $primary-red;
          font-size: 18px;
          font-weight: 700;
        }
      }
    }
  }
}
