@import '../../assets/styles/_mixins.scss';
@import '../../assets/styles/_variables.scss';
@import '../../assets/styles/_typography.scss';

.invoiceLoadingSection {
  width: 100%;
}

.container-refund-background {
  top: 100px;
  z-index: -1;
  page-break-after: always;
  transform: rotate(-45deg);
  -webkit-transform: rotate(320deg);
  color: $light-grey;
  font-weight: 700;
  font-size: 120px;
  letter-spacing: 5px;
  position: relative;
  white-space: nowrap;
  right: 50px;
  @include max-screen(small) {
    right: 0.25em;
    font-size: 55px;
    line-height: 1;
  }
  @include range-screen(small, medium) {
    font-size: 100px;
  }
}

.invoice-container {
  width: 100%;
  max-width: 210mm;
  box-shadow: 1px 2px 9px #cccccc;
  margin: 2em auto;
  padding: 2em;
  @include max-screen(medium) {
    padding: 0 !important;
  }
  .base-print-size {
    font-size: 1.5rem !important;
  }

  .invoice-receipt {
    margin: 3em !important;
    font-family: $font-family-primary;
    @include max-screen(medium) {
      margin: 0 !important;
    }
    .refundAdditionalText,
    .markupNote {
      margin-top: 2rem;
      @include max-screen(medium) {
        padding: 0em 2em;
      }
    }

    .invoice-content {
      display: flex;
      align-items: center;
      justify-content: space-between;
      @include max-screen(medium) {
        flex-direction: column;
        gap: 2em;
      }
    }

    .header {
      font-size: 2em;
      font-weight: bold;
      color: $primary-blue;
      @include max-screen(medium) {
        padding: 0em 1em;
        padding-top: 1em;
      }
    }

    .red-content {
      color: $primary-red !important;
    }

    .sub-header {
      font-size: 1.5em;
      font-weight: bold;
    }

    .column-header {
      font-weight: bold;
    }

    .api-logo {
      width: unset;
      height: 3em;
    }

    .content-100 {
      font-size: 1em;
      @include max-screen(medium) {
        padding: 0em 2em;
      }
    }

    .border-100 {
      border: 1px solid #aaaaaa;
      padding: 1em;
      @include max-screen(medium) {
        margin: 2em;
      }
    }

    .flex-start-justifybetween {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      @include max-screen(medium) {
        gap: 0.5em;
        padding: 0em 2em;
      }
    }

    .bottomSpacing {
      @include max-screen(medium) {
        padding-bottom: 2em;
      }
    }

    .title-section {
      gap: 1rem;
    }

    .detail-section {
      @include max-screen(medium) {
        padding: 0em 2em;
      }
    }

    .invoiceAddressWidths {
      width: 50%;
    }

    .wireTransferWidth {
      @include max-screen(medium) {
        padding: 2em 2em;
      }
    }
    .mailAddressesArea {
      justify-content: inherit;
      display: flex;
      flex-direction: column !important;
      gap: 1em;
    }

    .address-content {
      display: flex;
      flex-direction: column;
      line-height: 1.5em;
    }

    .flex-column-100 {
      display: flex;
      flex-direction: column;
      gap: 1em;
    }

    .paid-info {
      display: flex;
      align-items: top;
      justify-content: right;
      font-size: 1.5em;
      font-weight: bold;
      @include max-screen(medium) {
        padding-right: 2em;
      }
    }

    .paid-info-section {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      font-size: 1.5em;
      font-weight: bold;
      margin-top: 0.5em;
      @include max-screen(medium) {
        padding-right: 2em;
        padding-left: 2em;
      }
      
      .left-section {
        display: flex;
        flex-direction: column;
        gap: 0.5em;
        .wire-transfer-ins {
          font-size: 15px !important;
          font-weight: 400 !important;
        }
      }
    }

    .stampImage {
      width: 4em;
      height: fit-content;
    }
  }
}

.invoiceAddressSection {
  display: flex;
  align-items: baseline;
  margin: 2em 0em;
  justify-content: space-between;
  flex-direction: row !important;
  @include max-screen(small) {
    flex-direction: column !important;
  }

  .MuiGrid-container {
    align-items: baseline;
    justify-content: center;
    gap: 5%;
  }
}

.printAndDownload {
  display: flex;
  align-items: center;
  gap: 1em;
  justify-content: center;
  margin: 1em;
  @include max-screen(small) {
    flex-direction: column;
  }
  button {
    width: 15%;
    @include range-screen(tiny, small) {
      width: 50%;
    }
    @include range-screen(small, medium) {
      width: 30%;
    }
  }
}

@media print {
  .container-refund-background {
    display: block;
    counter-increment: page;
    position: fixed;
    left: 20px;
    margin-top: 40%;
    letter-spacing: 5px;
    -webkit-transform: rotate(330deg);
  }
  .invoice-container {
    width: 100%;
    max-width: 210mm;
    box-shadow: unset;
    margin: 1em 1em;
    padding: 1em;
    font-family: $font-family-primary;

    .invoice-receipt {
      font-size: 12pt;
      margin: 0;
      padding: 0;
      font-family: $font-family-primary;

      .printbtn,
      .downloadbtn {
        display: none !important;
      }
    }
  }
}
