@import '../../assets/styles/_variables.scss';
.ButtonContainer {
  position: fixed;
  bottom: 3.5rem;
  right: 3.5rem;
  align-items: center;
  height: 4.5rem;
  width: 4.5rem;
  justify-content: center;
  z-index: 2999;
  cursor: pointer;
  animation: fadeIn 0.3s;
  opacity: 0.5;
  background: $primary-blue;
  border-radius: 50%;
  transition: opacity 0.4s;
  display: flex;
  fill: $white;
  color: $white;
  border: 2px solid $white;
  &:hover {
    opacity: 1;
    background: $white;
    color: $primary-blue;
    fill: $primary-blue;
    border: 2px solid $primary-blue;
  }
}
