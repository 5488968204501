@import '../../assets/styles/_variables.scss';

.digitaldelivery {
  background: none;

  .checkoutPageTitle {
    font-weight: 700;
    font-size: 36px;
    line-height: 43px;
    color: $primary-blue;
    font-family: $font-family-primary;
    margin: 0 !important;
    font-family: $font-family-primary;
    padding: 1em 0em !important;
    span {
      font-weight: normal;
    }
  }

  .digitalDeliveryArea {
    p {
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 22px;
      color: $black;
      font-family: $font-family-primary;
      padding-left: 4.2rem;
    }
  }

  .reviewOrderSection {
    display: flex;
    flex-direction: row;
    .reviewOrderSNo {
      font-style: normal;
      font-weight: 700;
      font-size: 2.2rem;
      line-height: 0.3;
      padding: 1.5rem;
      color: $white;
      background: $primary-blue;
      aspect-ratio: 1/1;
      border-radius: 50%;
      margin-top: 0 !important;
      display: flex;
      align-items: center;
    }
    .reviewOrderTitle {
      margin-top: 0 !important;
      font-family: $font-family-primary;
      p {
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 41px;
        color: $primary-blue;
        font-family: $font-family-primary;
        vertical-align: middle;
        margin-bottom: 0 !important;
        margin-top: 0 !important;
      }
    }
    .reviewOrderEdit {
      margin-top: 0 !important;
      svg {
        color: $accent-blue;
      }
      a {
        text-decoration: underline;
        text-decoration: underline;
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 42px;
        text-decoration-line: underline;
        color: $accent-blue;
      }
    }
  }
}
.digitaldeliveryheader {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  color: $primary-blue;
  font-family: $font-family-primary;
}
.digitaldeliveryinfo {
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  color: $primary-blue;
  font-family: $font-family-primary;
}
.stepnobox {
  justify-content: center;
  align-items: center;
  background: $primary-blue;
  border: 3px solid $primary-blue;
  border-radius: 87px;
  color: $white;
  font-size: 18px;
  font-weight: 700;
  font-family: $font-family-primary;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  padding-left: 0.8em;
  padding-right: 0.8em;
  margin-right: 2%;
}
.divright {
  float: right;
  width: 94%;
  margin-top: 0.8%;
}
.divleft {
  float: left;
  width: 6%;
  margin-top: 1%;
}
