@import '../../assets/styles/_variables.scss';
@import '../../assets/styles/_typography.scss';

.productdescriptionsection {
  background-color: $lily-white;
  @include range-screen(tiny, xmedium) {
    padding: 1.5em;
  }
  @include range-screen(xmedium, large) {
    padding: 1em 3.5em 1em 3.5em;
  }
  @include min-screen(large) {
    padding: 2em 4em;
  }
  .productdescriptionheader {
    color: $black;
    font-weight: 700;
    font-family: $font-family-primary;
    font-size: 36px;
  }
  .productdescription {
    color: $black;
    font-weight: 400;
    font-family: $font-family-primary;
    font-size: 15px;
    line-height: 22px;
    text-align: justify;
  }
}
