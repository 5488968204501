@import '../../assets/styles/_mixins.scss';
@import '../../assets/styles/_variables.scss';
@import '../../assets/styles/_typography.scss';

.sub-disclaimer-section {
  background: $alert-red;
  color: $white;
  display: flex;
  justify-content: center;
  padding: 1em;
  gap: 1em;
  .disclaimer-text {
    display: flex;
    justify-content: center;
    font-size: 18px;
    line-height: 1.5em;
  }
  .MuiIconButton-label {
    font-size: 18px;
    color: $white;
  }
  .MuiIconButton-root {
    padding: 0px !important;
  }
}
