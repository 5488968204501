@import '../../assets/styles/mixins.scss';
@import '../../assets/styles/_variables.scss';
@import '../../assets/styles/_typography.scss';

.webstore-helpful-tips-section {
  margin: 2em;
  padding: 0.5em;
  font-family: $font-family-primary;
  .faq-header {
    margin: unset;
    padding: 0 0 0em 0.4em;
  }
  .language-select {
    font-family: $font-family-primary;
    padding-right: 5px;
    border: 8px solid $lily-white;
    height: fit-content !important;
    border-radius: 5px;
    .MuiSelect-icon {
      font-size: 20px;
      padding: 0 5px !important;
    }
    .MuiSelect-select:focus {
      background-color: transparent !important;
    }
    .status-label {
      font-size: 16px;
      color: $cool-grey-4;
      font-family: $font-family-primary;
    }
    .MuiListItemText-root {
      span {
        font-size: 16px !important;
      }
    }
    .MuiListItem-root {
      padding: 0 0.2em !important;
    }
    .MuiSelect-root {
      padding: 0 24px 0 12px !important;
    }
  }
  .helpful-tip-page-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-right: 16px !important;
    align-items: center;
  }
  @include max-screen(x-medium) {
    margin: 1em;
  }

  @include max-screen(small) {
    margin: 0.5em;
  }

  @include range-screen(tiny, medium) {
    li img {
      width: 100% !important;
      height: auto !important;
      max-width: 100% !important;
    }
  }

  .MuiAccordion-root {
    width: fit-content;
    width: initial;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0) !important;
  }
  .MuiAccordionSummary-content.Mui-expanded {
    margin: 0 0;
  }
  .MuiAccordion-root.Mui-expanded {
    margin-bottom: 0;
    margin-top: 0;
  }
  .MuiAccordionDetails-root {
    flex-direction: column !important;
    .MuiTypography-root {
      font-family: $font-family-primary;
    }
    .section-content {
      border: 1px solid lightgrey;
      padding: 1em 2em 2em 1em;
      @include max-screen(small) {
        padding: 0 1em 0 0;
      }
    }
  }
  .MuiAccordionSummary-content {
    .MuiTypography-root {
      font-family: $font-family-primary;
      font-style: normal;
      font-weight: 700;
      font-size: 1.3em;
      color: $primary-blue;
    }
  }
  .MuiAccordion-root::before {
    top: unset !important;
    right: unset !important;
    border: none !important;
  }
  .accordionOrderHistory {
    border-bottom: 2px solid $lily-white;
  }
  .accordionOrderHistory:last-child {
    border-bottom: none !important;
  }
}
