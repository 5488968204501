@import '../../assets/styles/_mixins.scss';
@import '../../assets/styles/_variables.scss';
@import '../../assets/styles/_typography.scss';

.MuiDialog-paperWidthSm {
  max-width: 550px !important;
}
.MuiDialog-container .MuiPaper-rounded {
  border-radius: 1% !important;

  .MuiDialogActions-root {
    padding: 1em;
    .MuiButtonBase-root {
      width: 50% !important;
      .MuiButton-label {
        font-size: 16px;
        min-height: 3rem;
      }
    }
  }
}
.addressValidationDialog {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: 0px;
  padding: 0px;
  width: 525px;
  @include range-screen(tiny, small) {
    width: 90% !important;
  }
  @include min-screen(small) {
    min-width: 500px;
  }
}

.addressValidationDialogPaper {
  border: 0;
  outline: none;
  position: fixed;
  box-shadow: 0px 11px 15px -7px rgb(0 0 0 / 60%), 0px 24px 38px 3px rgb(0 0 0 / 30%),
    0px 9px 46px 8px rgb(0 0 0 / 40%) !important;
  border-radius: 6px;
  background-color: #fff;
}
.addressDialog {
  .invalidHeader {
    background-color: $alert-red;
    padding: 0.75em 0em;
    margin-top: 0.5em;
    display: flex;
    .toastClose {
      font-size: 34px;
      cursor: pointer;
      vertical-align: middle;
      text-align: center;
      color: white;
      margin-right: 0.5em;
    }
    .invalidTextArea {
      padding: 0em 1em;
      .invalidAddressMsg {
        margin: 0 !important;
        color: $white;
        text-align: center;
      }
    }
  }
}
.MuiDialog-paper {
  max-width: 550px;
  border-radius: 1%;
  .MuiDialogTitle-root {
    .MuiTypography-root {
      font-family: $font-family-primary !important;
      font-weight: 600;
      padding: 0em 0.7em;
    }
  }
  .MuiDialogContent-root {
    display: flex;
    flex-direction: column;
    gap: 1em;
    padding: 0em 2em;
    .AddressStreet,
    .AddressSuit,
    .AddressPhoneNumber {
      .addressField {
        font-weight: 700;
      }
    }
    .CityStateSection {
      display: flex;
      flex-direction: row;
      gap: 1em;
      .AddressCity,
      .AddressState,
      .AddressPostalCode {
        .addressField {
          font-weight: 700;
        }
        .MuiTextField-root {
          width: 178px !important;
        }
      }
    }
    .MuiTextField-root {
      width: 570px;
    }

    .addressinfoheader {
      font-size: 17px;
    }
    .errormessage {
      font-weight: 400;
      color: $alert-red;
      font-size: 13px;
    }
    .street-header-div {
      display: flex;
      justify-content: space-between;
    }
    .po-disclaimer {
      color: $accent-blue;
      font-size: 14px;
    }
    .countryNoteDiv {
      margin-top: 10px;
    }
    .countryNote {
      font-size: 1.3rem;
      line-height: 0;
    }
    .countrydiv {
      padding-top: 1em;
      .inputtext {
        color: $textinput;
        font-weight: 400;
        border: 1px solid $light-grey;
        font-family: $font-family-primary;
        width: -webkit-fill-available;
        padding: 0.75em;
      }
    }
    .addressdivs {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      gap: 1.2em;
      .inputtext {
        width: 90%;
        color: $textinput;
        font-weight: 400;
        border: 1px solid $light-grey;
        font-family: $font-family-primary;
      }
      .citydiv {
        width: 30%;
        margin-right: 0;
        .inputtext {
          width: -webkit-fill-available;
          padding: 0.75em;
        }
      }
      .stdiv {
        width: 30%;
        margin-right: 0;
        .inputtext {
          width: -webkit-fill-available;

          padding: 0.75em;
        }
      }
      .postaldiv {
        width: 30%;
        .inputtext {
          width: -webkit-fill-available;

          padding: 0.75em;
        }
      }
    }
    .shippingCheck {
      padding-bottom: 1em;
    }
    .streetdiv {
      .inputtext {
        width: -webkit-fill-available;
        border: 1px solid $light-grey;

        padding: 0.75em;
      }
    }
    .unitdiv {
      float: left;
      width: 100%;
      margin-right: 0;

      .inputtext {
        width: -webkit-fill-available;
        border: 1px solid $light-grey;

        padding: 0.75em;
      }
    }
    .phonediv {
      padding: 0;

      .inputtext {
        width: -webkit-fill-available;
        border: 1px solid $light-grey;

        padding: 0.75em;
      }
    }
  }
}
.addNewAddress {
  .MuiButton-root {
    background: none;
    background-color: $white !important;
    color: inherit;
    border: none !important;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
    .MuiButton-label {
      font-family: $font-family-primary;
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 22px;
      text-decoration-line: underline;
      color: $accent-blue;
    }
  }
}
