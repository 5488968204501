@import '../../assets/styles/mixins.scss';
@import '../../assets/styles/_variables.scss';
@import '../../assets/styles/_typography.scss';

.salesPrice {
  padding: 0 !important;
  padding-top: 1em !important;
  .salesPriceSection {
    @include range-screen(tiny, medium) {
      width: fit-content;
    }
    .salesPriceProductTitle {
      font-weight: 700;
      font-size: 24px;
      line-height: 29px;
      padding: 1em 0 !important;
      padding-bottom: 1em !important;
      word-wrap: break-word;
      width: 550px;
      @include range-screen(tiny, medium) {
        width: fit-content;
      }
      @include max-screen(small) {
        padding-bottom: 0.5em !important;
        padding-top: 0.5em !important;
      }
    }
  }
}
.salesprice {
  background: $white;
  font-family: $font-family-primary;
  padding: 0.5em 2em 2em 2em !important;
  width: fit-content;
  .salesPriceNote {
    p {
      display: flex;
      span {
        font-weight: normal;
      }
      font-style: normal;
      font-weight: 700;
      @include font-size(1.125);
      line-height: 22px;
      color: $black;
    }
  }

  .salesPriceSection {
    width: fit-content;
    @include range-screen(tiny, medium) {
      width: fit-content;
    }
    .salesPriceProductTitle {
      font-weight: 700;
      font-size: 24px;
      line-height: 29px;
      padding: 1em 0 !important;
      padding-bottom: 1em !important;
      word-wrap: break-word;
      width: 550px;
      @include range-screen(tiny, medium) {
        width: fit-content;
      }
      @include max-screen(small) {
        padding-bottom: 0.5em !important;
        padding-top: 0.5em !important;
      }
    }

    .AvailableLinks {
      font-weight: 700;
      font-size: 18px;
      line-height: 22px;
      color: $white;
      font-family: $font-family-primary;
    }

    .MuiAccordion-root {
      width: fit-content;
      outline: 0 !important;
      margin: 0 !important;
      width: initial;
      box-shadow: 0 0 0 0 rgba(0, 0, 0, 0) !important;
    }
    .MuiAccordionDetails-root {
      flex-direction: column !important;
      padding: 0;
    }
    .MuiAccordionSummary-root {
      @include range-screen(tiny, medium) {
        padding: 0;
      }
    }
    .MuiAccordionSummary-content {
      p {
        color: #2b7bb9 !important;
        font-weight: 700;
        font-size: 18px;
        line-height: 22px;
        margin-left: 247px;
        text-decoration: underline;
        @include range-screen(tiny, medium) {
          margin-left: 0;
        }
      }
    }
  }
}

.cartTitleProductInactive {
  color: $primary-red;
}
