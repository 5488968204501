@import '../../assets/styles/_variables.scss';

.taxexemptstatus {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: $primary-blue;
  width: 100%;
  padding-right: 2rem;
  .taxexemptdiv {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
  }
  .taxexemptstatusdiv {
    display: flex;
    margin-left: 2rem !important;
    margin-bottom: 2rem !important;
  }
  .taxexempttext {
    color: $white;
    font-size: 18px;
    font-family: $font-family-primary;
  }
  .taxexempttextspan {
    color: $white;
    font-size: 18px;
    display: flex;
    align-items: center;
    gap: 1rem;
    font-family: $font-family-primary;
  }
  .notaxexempttext {
    color: $white;
    font-size: 18px;
    font-family: $font-family-primary;
    margin-right: 0.25em;
  }
  .taxexemptverifylink {
    color: $white;
    font-size: 18px;
    font-family: $font-family-primary;
    padding-right: 0.25em;
  }
  .facheckcircle {
    color: $mostrecent-primary-green !important;
    padding-right: 0.2em;
  }
  .facheckexclamation {
    color: $alert-red !important;
  }
  .salestax {
    color: $white;
    font-size: 14px;
    font-family: $font-family-primary;
    padding-left: 8em;
  }
}
