.cart-page-main {
  display: flex;
  flex-direction: row;
  width: 100% !important;
  margin: 0 !important;
  .toastSection {
    .toast-container {
      position: fixed;
      top: 0;
      width: 100%;
      z-index: 999;
      .toast-body {
        a {
          color: white;
        }
      }
    }
  }
}
