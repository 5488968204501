.statistic-store-grid {
  gap: 0.5em;
  display: flex;
  flex-direction: column;
  .title {
    color: #17457a;
    font-size: 36px;
    font-weight: 700;
    line-height: 43px;
    margin-top: 0;
    margin-bottom: 0.5em;
  }
  .no-records-text {
    font-weight: 700;
    font-size: 18px;
    line-height: 43px;
    margin-top: 0;
    text-align: center;
  }
  .statistics-report {
    border: 1px solid #c7c9c8;
    justify-content: space-between;
    gap: 0.5em;
  }
  .result-subheader {
    float: left;
    gap: 5px;
    .result-sub-details {
      align-items: center;
      display: flex;
      padding-right: 1em;
    }
    b {
      padding-right: 5px;
    }
  }
  .description {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: -webkit-nowrap;
    -webkit-line-clamp: 3;
    display: -webkit-box;
    -webkit-box-pack: end;
    -webkit-box-orient: vertical;
    line-height: 3.6rem;
  }
  .result-header {
    color: #17457a;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 29px;
    -webkit-text-decoration-line: underline;
    text-decoration-line: underline;
    margin: 0;
  }
  .report-details {
    display: flex;
    flex-direction: column;
    margin: 20px;
    gap: 10px;
  }
  .report-download {
    display: flex;
    align-items: center;
    background: #17457a;
    justify-content: center;
    margin: 0 0 0 0.5em;
    min-height: 20rem;
    width: auto;
    min-width: 20rem;
  }
  .download-error {
    color: red;
    margin-left: 5px;
  }
}
