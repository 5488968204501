@import '../../assets/styles/mixins.scss';
@import '../../assets/styles/_variables.scss';
@import '../../assets/styles/_typography.scss';

.contactus {
  .contactus-div {
    @include range-screen(tiny, large) {
      width: 90%;
      margin: 0em 5% 0em 5%;
    }
    @include min-screen(large) {
      width: 60%;
      margin-left: auto;
      margin-right: auto;
    }
  }
  .hs-input {
    width: 96%;
    color: $black;
    padding: 0.75em;
    font-family: $font-family-primary;
    border: 1px solid #c7c9c8;
  }
  .hs-fieldtype-text {
    font-size: 15px;
    font-family: $font-family-primary;
  }
  .hubspot_form {
    background-color: $light-grey;
    border: 1px solid $black;
  }
  div[id^='reactHubspotForm'] {
    border: 1px solid $primary-blue;
    padding: 20px;
    margin-bottom: 2em;
  }
  .hs-richtext {
    font-size: 15px;
    font-family: $font-family-primary;
    width: 90%;
    text-align: justify;
  }
  .hs-form-field {
    padding-bottom: 1em;
  }
  .hs-button {
    background-color: $primary-blue !important;
    color: $white !important;
    margin: 0 !important;
    padding: 0.5em 2em !important;
    border: 3px solid $white !important;
    border-radius: 34px !important;
    font-family: $font-family-primary;
    font-weight: 700 !important;
    font-size: 1em;
    min-height: 3em;
    cursor: pointer;
    transition: 0.3s;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14),
      0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  }
  .hs-button:hover {
    background-color: $white !important;
    border: 3px solid $primary-blue;
    color: $primary-blue !important;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14),
      0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  }
  .hs-button:focus {
    background-color: $primary-blue;
    color: $white;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14),
      0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  }
  .hs_submit {
    width: fit-content;
    margin: 0 auto;
  }
  .hs-error-msgs {
    display: contents !important;
    color: $alert-red;
    font-size: 15px;
    list-style: none;
  }
  input[type='email']:placeholder-shown {
    border: 1px solid $light-grey;
  }
  .grecaptcha-badge,
  .g-recaptcha {
    transform: scale(0.77);
    -webkit-transform: scale(0.77);
    transform-origin: 0 0;
    -webkit-transform-origin: 0 0;
  }
}
