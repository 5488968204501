@import '../../assets/styles/_mixins.scss';
@import '../../assets/styles/_variables.scss';
@import '../../assets/styles/_typography.scss';

.cartDrawer {
  .MuiDrawer-paperAnchorRight {
    background: white !important;
    width: fit-content;
    @include range-screen(tiny, small) {
      width: 100%;
    }
    @include range-screen(small, medium) {
      width: 70%;
    }
    h1 {
      padding: 0 !important;
      margin: 0 !important;
      width: fit-content;
      padding: 0em 1em !important;
    }
    .cartNavigationLinkSection {
      height: fit-content !important;
      background: #17457a;
      padding: 2em;
      @include max-screen(small){
        padding: 1em;
      }
      p {
        color: white;
        margin-top: 0;
        font-size: 2.5rem !important;
        .cartSubTotal {
          float: right;
          font-family: $font-family-primary !important;
          font-weight: 600 !important;
        }
      }
      .cartNavButtons {
        display: flex;
        flex-direction: row;
        gap: 1em;
        @include max-screen(xmedium){
          flex-direction: column;
        }
        .addtoCartButton {
          text-align: center;
          min-width: 35%;
          white-space: nowrap;
          border-radius: 50px;
          font-size: 18px;
          padding-top: 0.75rem;
          padding-bottom: 0.75rem;
          font-weight: bold;
          background: $primary-blue;
          color: $white;
          border: 3px solid $white;
          text-decoration: none;
          ::hover {
            color: $white;
          }
        }
        .add-to-cart-btn-disable {
          pointer-events: none;
          background-color: $dark-grey !important;
          border: 3px solid darkgray !important;
          color: $lightgrey !important;
        }
        .addtoCartAndCheckoutButton {
          white-space: nowrap;
          min-width: 60%;
        }
      }
    }
    .salesPrice {
      padding: 0 !important;
      padding-top: 1em !important;
      .salesPriceSection {
        width: max-content;
        .salesPriceProductTitle {
          font-weight: 700;
          font-size: 24px;
          line-height: 29px;
          padding: 1em 0 !important;
          padding-bottom: 1em !important;
          word-wrap: break-word;
          width: 550px;
        }
      }
    }
  }
}
.cart {
  padding: 0rem 1rem !important;

  .cartLink {
    display: flex;
  }
  .MuiBadge-badge {
    z-index: unset;
    aspect-ratio: 1/1;
  }

  .icon {
    fill: $white;
    margin-bottom: 0.5%;
    font-size: 25px;
    @include font-size(2.7);
    vertical-align: -0.25em;
    padding-right: 0.25em;
  }
  a {
    color: $white;
    text-decoration: none;
    @include font-size(1.8);
    font-weight: 400;
    outline: none;
    font-family: $font-family-primary;
    font-size: 18px !important;
    text-decoration: none;
    vertical-align: middle;
  }
  .cartSlider {
    background-color: white !important;
    .cartNavigationLinkSection {
      background-color: #17457a;
      height: 25vh;
      @include max-screen(small){
        padding: 1em;
      }
      .addtoCartButton {
        text-align: center;
        white-space: nowrap;
        width: 35%;
        border-radius: 50px;
        font-size: 18px;
        padding: 0.5em 2.5em;
        font-weight: bold;
        background: #17457a;
        color: white;
        border: 3px solid white;
        ::hover {
          color: white;
        }

        .addtoCartAndCheckoutButton {
          white-space: nowrap;
          width: 60% !important;
        }
      }
    }
  }
  .MuiBadge-anchorOriginTopRightRectangular {
    right: unset;
    transform: scale(1) translate(-50%, -50%);
    transform-origin: 100% 0%;
    left: 0;
    top: 0;
  }
  .MuiBadge-colorPrimary {
    background-color: $primary-red;
    color: $white;
    font-size: 10px;
  }
}
