@import url('https://use.typekit.net/oog5rbx.css');
// BREAKPOINTS (Media Queries)  =============================================== *
$breakpoints: (
  'tiny': 0px,
  'xsmall': 330px,
  'small': 600px,
  'xmedium': 820px,
  'medium': 960px,
  'large': 1280px,
  'xlarge': 1921px,
);

// Colors  =============================================== *
//Base
$white: #fff;
$black: #333738;
$pure-black: #000000;

// Primary
$primary-blue: #17457a;
$primary-red: #cd163f;

// Accent
$accent-blue: #2b7bb9;
$link-blue: #0056b3;
$accent-dark-purple: #17457a;
$pure-blue: #0090ff;

// Greys
$grey: #efefef;
$cool-grey-1: #dbd9d6;
$cool-grey-4: #bdbbbb;
$cool-grey-5: #c7c9c8;
$cool-grey-7: #9a9a9b;
$cool-grey-8: #797d82;
$cool-grey-9: #666d70;
$cool-grey-11: #363533;
$alto-grey: #dedede;
$concrete: #f3f3f3;
$iron-grey: #e0e1e2;
$silver-foil: #afb0b1;
$lily-white: #e9ebea;
$light-grey: #c7c9c8;
$grayish-blue: #aeb0b5;
$darkslategrey: #25383c;
$dark-grey: #808080;
$lightgrey: #d3d3d3;
$lightergrey: #f8f8f8;

// Alert System
$alert-red: #cd163f;
$alert-orange: #f47721;
$alert-yellow: #fbaf5d;
$alert-green: #228747;

//Breadcrumbs
$breadcrumb-primary: #00819f;
$breadcrumb-current: #1b1c1d;

//font-family
$font-family-primary: 'urw-din' !important;
$font-family-faIcon: 'FontAwesome' !important;

//common
$none: none;

//Most recent text color
$mostrecent-primary-green: #8dc926;
$toast-green: #78a22f;

//Previous edition text
$previous-edition-primary: #af7aff;

// Shipping information address text
$textinput: #797d82;

// Tax Exempt
$expiresoon: #ffb400;

//left One Tab Width
$leftTabWidth: calc(4em + 120px + 20px + 2px);

//left One Tab Width without margin
$leftTabWidthWithoutMargin: calc(120px + 20px + 2px);

@font-face {
  font-family: 'FontAwesome';
  font-weight: normal;
  font-style: normal;
  src: url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.4.2/webfonts/fa-brands-400.ttf')
}
