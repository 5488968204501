@import '../../assets/styles/_mixins.scss';
@import '../../assets/styles/_variables.scss';
@import '../../assets/styles/_typography.scss';

.shareLinkDialog {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: 0px;
  padding: 0px;
  @include range-screen(tiny, small) {
    width: 90% !important;
  }
  .MuiDialogContent-root {
    .link-div {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      align-content: center;
      gap: 1.5rem;
      padding: 0.5em;
      .inputtext {
        border: 2px solid $accent-blue;
        padding: 0.5em 0.75em;
        font-size: 16px;
        width: 300px;
      }
      .share-link-content {
        gap: 1em;
        display: flex;
        align-items: center;
      }
      .close-icon {
        font-size: 18px;
        padding: 0 0 0 0.5em;
      }
      .share-link-header {        
        justify-content: space-between;
        display: flex;
      }
    }
  }
  .share-link-dialog-content {
    padding: 0 !important;
  }
  .ws-primary-button-no-border:disabled {
    background-color: $lightgrey !important;
    color: $white !important;
    border: 1px solid $grey !important;
  }
  .MuiDialogActions-root {
    gap: 1.5rem !important;
    padding: 0px !important;
    button {
      width: 45% !important;
    }
  }
  .MuiDialogContent-dividers {
    border: none !important;
  }
  .MuiDialogActions-spacing {
    padding: 0px 8px 0px 8px !important;
  }
  .copy-success {
    color: $mostrecent-primary-green;
    font-size: 16px;
    justify-content: center;
    display: flex;
  }
  .link-header {
    display: flex;
  }
  .ws-white-button {
    min-height: 1em !important;
    padding: 0px 1.5em !important;
  }
}

.shareLinkDialogPaper {
  border: 0;
  outline: none;
  position: fixed;
  box-shadow: 0px 11px 15px -7px rgb(0 0 0 / 60%), 0px 24px 38px 3px rgb(0 0 0 / 30%),
    0px 9px 46px 8px rgb(0 0 0 / 40%) !important;
  border-radius: 6px;
  background-color: $white;
  padding: 2rem;
}
