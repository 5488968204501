@import '../../assets/styles/_variables.scss';
@import '../../assets/styles/_typography.scss';

.product-info-section {
  height: 100% !important;
  margin: 2em 4em;
  font-family: $font-family-primary !important;
  @include max-screen(medium) {
    margin: 2em;
  }
  @include max-screen(small) {
    margin: 1em;
  }

  h3 {
    margin: 0 !important;
  }

  h1 {
    color: $primary-blue;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    margin: 0 !important;
    padding-bottom: 0.5em !important;
  }

  .horizontalLine {
    border: 0.5px solid #c7c9c8;
  }

  .product-group-margin {
    margin-top: 1.5em !important;
  }

  .products-section {
    ul,
    li {
      list-style: none;
      margin: 0;
      padding: 0;
    }
    ul {
      padding-left: 1em;
    }

    li {
      padding-left: 1em;
      border: 1px dotted black;
      border-width: 0 0 1px 1px;
    }

    li.container {
      border-bottom: 0px;
    }

    li.empty {
      font-style: italic;
      color: silver;
      border-color: silver;
    }

    li p {
      margin: 0;
      background: white;
      position: relative;
      top: 0.75em;
      line-height: 32px;
      .product-available-info {
        font-size: 15px;
      }
    }

    li ul {
      border-top: 1px dotted black;
      margin-left: -1em;
      padding-left: 2em;
    }
    ul li:last-child ul {
      border-left: 1px solid white;
      margin-left: -17px;
    }
  }

  .orderAgreementText {
    p {
      margin-top: 0 !important;
    }
  }

  .main-rep-section {
    .col-heading {
      color: $primary-blue;
      font-weight: bold;
    }

    .subscription-rep-details,
    .subscription-account-details {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      column-gap: 2em;
      row-gap: 0.5em;
      padding: 0.5em 0 !important;
    }
  }
}
