@import '../../assets/styles/_mixins.scss';
@import '../../assets/styles/_variables.scss';
@import '../../assets/styles/_typography.scss';

.productmetadata {
  background: $primary-blue;
  .mainProductMetaDiv {
    padding: 0em 2em 0em 4em;
    @include min-screen(xmedium) {
      display: flex;
    }
    .productMetaDataLeft {
      padding-right: 20px;
    }
  }
  .share-icon-div {
    width: 5%;
    margin-left: 1.5em;
    cursor: pointer;
  }
  .shareicon {
    color: $white;
    font-size: 45px;
  }
  .product-meta-data-details {
    display: flex;
    flex-direction: row;
  }

  .product-align {
    display: flex;
    flex-direction: column-reverse;
  }

  .AddendaSection,
  .PreviousEditionSection,
  .RedlineAvailableSection {
    display: flex;
    .tooltip {
      padding: 0 !important;
      margin: 0 !important;
      height: fit-content;
      .tooltipArea {
        margin-top: -1px;
        margin-left: 3px;
        .MuiTooltip-tooltip {
          white-space: break-spaces;
        }
        .MuiButton-root {
          padding-top: 0.4em;
          .MuiButton-label {
            .MuiSvgIcon-root {
              font-size: 2.5rem;
            }
          }
        }
      }
    }
  }
  .producttitle {
    font-style: normal;
    font-weight: 700;

    color: $white;
    flex-direction: row;
    font-family: $font-family-primary;
    padding-bottom: 0.25em;
    @include range-screen(tiny, xmedium) {
      font-size: 32px;
      line-height: 32px;
    }
    @include min-screen(xmedium) {
      font-size: 54px;
      line-height: 54px;
    }
  }

  .reaffirmedfieldvalue {
    font-family: $font-family-primary;
    font-style: normal;
    font-size: 18px;
    color: $white;
    font-weight: 100 !important;
    min-width: 15%;
  }

  .reaffirmedTitleSection {
    display: flex;

    .tooltip {
      padding: 0 !important;
      margin: 0 !important;
      height: fit-content;
      .tooltipArea {
        margin-top: -1px;
        margin-left: 3px;
        .MuiButton-root {
          padding-top: 0.4em;
          .MuiButton-label {
            .MuiSvgIcon-root {
              font-size: 2.5rem;
            }
          }
        }
      }
    }
  }

  .productfields {
    display: flex;
    flex-wrap: wrap;
  }

  .productmetadatafields {
    float: left;
    padding: 0rem;
  }

  .productmetadatafieldtitle {
    font-family: $font-family-primary;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    color: $white;
    white-space: nowrap;
    min-width: 15%;
  }
  .productmetadatafieldvalue {
    font-family: $font-family-primary;
    font-style: normal;
    font-size: 18px;
    color: $white;
    font-weight: 100 !important;
    min-width: 15%;
  }
  .tablecell {
    padding-right: 1em;
    padding-left: 1em;
    border-right: 1px solid white;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    border-bottom: none !important;
    text-align: left;
  }
  .tablecelllast {
    padding-left: 1em;
    text-align: left;
    border-bottom: none !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .right-divider {
    height: 60px;
    width: 1px;
    background-color: $white;
    margin: 0rem 3rem;
    display: block;
    float: left;
  }
  .tablecellfirst {
    padding-right: 1em;
    border-right: 1px solid white;
    text-align: left;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    padding-left: 0 !important;
    border-bottom: none !important;
  }
  .mostrecenttext {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    color: $mostrecent-primary-green;
    font-family: $font-family-primary;
  }
  .checkcircleicon {
    font-weight: 900;
    font-size: 18px;
    line-height: 18px;
    color: $mostrecent-primary-green;
    padding-right: 0.3em;
  }
  .previoustext {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    color: $previous-edition-primary;
    font-family: $font-family-primary;
  }
  .previousicon {
    font-weight: 900;
    font-size: 18px;
    line-height: 18px;
    color: $previous-edition-primary;
    padding-right: 0.3em;
  }
  .mostrecentdiv {
    padding-bottom: 1em;
  }
  .questionicon {
    font-style: normal;
    font-weight: 100 !important;
    font-size: 11px;
    color: $white;
    padding-bottom: 0.2em;
    position: relative;
    left: 0.5px;
    bottom: 1px;
  }
}
