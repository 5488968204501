@import '../../assets/styles/_mixins.scss';
@import '../../assets/styles/_variables.scss';
@import '../../assets/styles/_typography.scss';

.addressValidationDialog {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: 0px;
  padding: 0px;
  width: 525px;
  @include range-screen(tiny, small) {
    width: 90% !important;
  }
  @include min-screen(small) {
    min-width: 500px;
  }
}

.addressValidationDialogPaper {
  border: 0;
  outline: none;
  position: fixed;
  box-shadow: 0px 11px 15px -7px rgb(0 0 0 / 60%), 0px 24px 38px 3px rgb(0 0 0 / 30%),
    0px 9px 46px 8px rgb(0 0 0 / 40%) !important;
  border-radius: 6px;
  background-color: #fff;
}

.placeorderprocess {
  color: purple;
  display: block;
  align-items: center;
  align-content: center;
  .checkoutButtonDisable {
    background-color: grey !important;
    .checkoutButtonTextDisable {
      color: lightgrey !important;
    }
  }
  .checkoutButton {
    background-color: $primary-blue !important;
    .checkoutButtonText {
      color: white !important;
    }
  }
  .placeOrderButton {
    padding: 1em 0em;
    .MuiButton-containedSecondary {
      width: 100%;
      height: 5rem;
      background-color: $primary-blue;
      .MuiButton-label {
        color: $white;
        text-decoration: none;
        font-family: $font-family-primary;
        font-style: normal;
        font-weight: 700;
      }
    }
  }
  .loadingSection {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.6);
  }
  .loading-container {
    padding: 20px;
    box-shadow: 0px 0px 5px 5px darkgrey;
    border-radius: 20px;
    background-color: rgba(255, 255, 255);
    align-items: center;
    justify-items: center;
  }
  .loading-text {
    color: $primary-blue;
    height: 200px;
  }

  @keyframes ldio-6of74k5rumc-1 {
    0% {
      top: 36px;
      height: 128px;
    }
    50% {
      top: 60px;
      height: 80px;
    }
    100% {
      top: 60px;
      height: 80px;
    }
  }
  @keyframes ldio-6of74k5rumc-2 {
    0% {
      top: 41.99999999999999px;
      height: 116.00000000000001px;
    }
    50% {
      top: 60px;
      height: 80px;
    }
    100% {
      top: 60px;
      height: 80px;
    }
  }
  @keyframes ldio-6of74k5rumc-3 {
    0% {
      top: 48px;
      height: 104px;
    }
    50% {
      top: 60px;
      height: 80px;
    }
    100% {
      top: 60px;
      height: 80px;
    }
  }
  .loading-spinner div {
    position: absolute;
    width: 20px;
  }
  .loading-spinner div:nth-child(1) {
    left: 35px;
    background: #0051a2;
    animation: ldio-6of74k5rumc-1 1s cubic-bezier(0, 0.5, 0.5, 1) infinite;
    animation-delay: -0.2s;
  }
  .loading-spinner div:nth-child(2) {
    left: 75px;
    background: #1b75be;
    animation: ldio-6of74k5rumc-2 1s cubic-bezier(0, 0.5, 0.5, 1) infinite;
    animation-delay: -0.1s;
  }
  .loading-spinner div:nth-child(3) {
    left: 115px;
    background: #408ee0;
    animation: ldio-6of74k5rumc-3 1s cubic-bezier(0, 0.5, 0.5, 1) infinite;
    animation-delay: undefineds;
  }

  .loading-spinner {
    width: 100%;
    height: 70%;
    position: relative;
    transform: translateZ(0) scale(1);
    backface-visibility: hidden;
    transform-origin: 0 0; /* see note above */
    margin-left: auto;
    margin-right: auto;
    margin-left: 33%;
  }
  .loading-spinner div {
    box-sizing: content-box;
  }
  .spanmessage {
    color: $black;
    font-family: $font-family-primary;
    font-size: 15px;
    font-weight: 400;
    display: block;
    margin-left: 20%;
  }
  .spandiv {
    width: 40vw;
  }
  .toast-header {
    float: right;
    color: white;
    background: $alert-red;
    padding-top: 1em;
    padding-bottom: 1em;
  }
  .toast-body {
    color: white;
    background: $alert-red;
    padding-top: 1em;
    padding-bottom: 1em;
  }
}
