@import '../../assets/styles/_variables.scss';
@import '../../assets/styles/_typography.scss';

.previouseditionssection {
  background: $white;
  @include range-screen(tiny, small) {
    padding: 0em 1.5em 0.5em 1.5em;
  }
  @include range-screen(small, medium) {
    padding: 0em 3.5em 1em 3.5em;
  }
  @include min-screen(medium) {
    padding: 0em 3.5em;
  }

  h1 {
    margin-left: 0px;
  }

  .site-search-search-button {
    .viewDetails{
      text-decoration: none;
    }
    .viewDetails:hover {
      text-decoration: none;
      .MuiButton-label {
        a {
          outline: none;
          color: white !important;
        }
      }
    }
    .MuiButton-label {
      a {
        outline: none;
      }
    }
  }

  .previouseditionheader {
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 43px;
    color: $primary-blue;
    font-family: $font-family-primary;
    margin-bottom: 0.5%;
  }
  .previouseditionhelp {
    font-style: normal;
    font-weight: 200;
    font-size: 18px;
    color: $primary-blue;
    font-family: $font-family-primary;
  }
  .previouseditionhelpemail {
    font-style: normal;
    font-weight: 200;
    font-size: 18px;
    color: $primary-blue;
    font-family: $font-family-primary;
  }
  .btnviewdetails {
    border-radius: 20px;
    border: 2px solid $primary-blue;
    color: $primary-blue;
    font-size: 14px;
    font-weight: 700;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    padding-left: 2em;
    padding-right: 2em;
    background: transparent;
    text-decoration: none;
  }
  .previoustext {
    font-size: 14px;
    font-weight: 700;
    color: $previous-edition-primary !important;
    padding-top: 0.5em;
    font-family: $font-family-primary;
  }
  .previoustextMain{
    padding-right:1em;
  }
  .defaultEdition{
    padding-left:0.25em;
  }
  .icon {
    padding-right: 0.4em;
  }
  .prevname {
    color: $primary-blue !important;
    font-size: 18px;
    font-weight: 700;
    font-family: $font-family-primary;
  }
  .prevtitle {
    color: $black !important;
    font-size: 14px;
    font-weight: 100;
    font-family: $font-family-primary;
    .defaultLanguage{
      padding-left: 0.25em;
      padding-right: 0.25em;
    }
  }
  .subh3 {
    color: $primary-blue !important;
    font-size: 18px;
    font-weight: 700;
    font-family: $font-family-primary;
  }
  .griditembackgroundwhite {
    text-align: left !important;
    padding: 0 !important;
    margin-top: 1%;
    margin-bottom: 1% !important;
    background-color: $white !important;
    padding-bottom: 1em !important;
    padding-top: 1em !important;
  }
  .griditembackgroundlilywhite {
    text-align: left !important;
    padding: 0 !important;
    margin-top: 1%;
    margin-bottom: 1% !important;
    background-color: $lily-white !important;
    padding-bottom: 1em !important;
    padding-top: 1em !important;
  }
  .publisheddate {
    color: $primary-red !important;
    font-size: 16px;
    font-weight: 700;
  }
  .divsection {
    margin-bottom: 1%;
  }
  .divitem {
    margin-bottom: 1rem;
  }
  .MuiPaper-root {
    background-color: transparent !important;
  }
  .jss3 {
    background-color: transparent !important;
  }
  .MuiGrid-item {
    box-sizing: unset !important;
    padding-bottom: 0em !important;
    padding-top: 0em !important;
  }
  .MuiGrid-container {
    display: inherit !important;
  }
  .MuiGrid-grid-md-4 {
    max-width: 100% !important;
    flex-basis: 100% !important;
  }
  .MuiPaper-elevation1 {
    box-shadow: none !important;
  }
}
