@import '../../assets/styles/mixins';
@import '../../assets/styles/variables';
@import '../../assets/styles/typography';

.searchField {
  color: $primary-blue !important;
}

.no-border-box {
  fieldset {
    border-width: 0px !important;
  }
}

.site-search-form-wrapper {
  display: flex;
  margin-top: 10px;
  flex-direction: column;
  row-gap: 17px;
  @include max-screen(small) {
    row-gap: 2px;
    margin-top: 0;
  }
  .browse-button-div {
    display: flex;
    flex-direction: row;
    gap: 0.5em;
    @include max-screen(small) {
      gap: 2px;
      .ws-primary-button-no-border {
        padding: 0.5em 0.8em !important;
      }
    }
  }

  .browseAllLink {
    text-decoration: none;
    color: white;
    width: fit-content;
  }

  .browseAll {
    @include max-screen(small) {
      width: unset !important;
    }
    width: 195px;
  }

  .browseAll:hover {
    text-decoration: none;
    color: $primary-blue;
  }

  .searchFieldWrapper {
    background: $lily-white;
    justify-content: center;
    display: flex;
    height: 2.8em;
    margin: 10px 0;

    .MuiAutocomplete-input {
      padding: 7px 4px !important;
    }

    .siteSearchField {
      width: 100%;
      border: 0;
      font-size: 22px;
      background-color: white;
    }

    .SearchIcon {
      width: 3em;
      background: $primary-red;
      color: $white;
      border: 0;
      padding: 5px 5px 0 5px;
      font-size: 18px;
    }
  }
}

.site-search-search-button {
  .siteSearchButton,
  #Search {
    border-radius: 50px;
    border: none;
    background: $primary-blue;
    color: $white;
    font-size: 18px;
    padding: 0.5em 2.5em;
    font-weight: bold;
    text-decoration: none;
  }
}
