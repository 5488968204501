@import '../../assets/styles/_variables.scss';
@import '../../assets/styles/_typography.scss';

.trendingstandards {
  .TrendingStandardItem {
    background: white;
    text-align: left;
    padding: 0.5em !important;
    box-shadow: none;
  }
  .TrendingStandardHeading {
    font-style: normal;
    font-weight: bolder;
    font-size: 54px;
    color: $primary-red;
    margin: 0;
    padding: 0.5em 1.6em !important;

    @include range-screen(tiny, medium) {
      padding: 0.5em 0.75em !important;
      font-size: 36px;
    }

    @include min-screen(medium) {
      padding: 0.5em 1.6em !important;
      font-size: 44px;
    }
  }
  .TrendingStandardDescription {
    font-style: normal;
    line-height: 29px;
    color: #333738;
    margin: 0 !important;

    @include range-screen(tiny, medium) {
      padding: 0 1.75em !important;
      font-size: 18px;
    }

    @include min-screen(medium) {
      padding: 0 4em !important;
    }
  }
  .trendingStandardsItemsContainer {
    background: $white;
    padding: 0.5em 3.5em;

    @include range-screen(tiny, medium) {
      padding: 0.5em 1.25em;
    }

    .MuiGrid-container {
      row-gap: 1em;
    }

    .seeStandardsSection {
      padding: 1em 0.25em;
      font-size: 30px;

      svg {
        vertical-align: top;
        fill: $primary-red;
      }
    }
    .seeAllTrendingStandards {
      font-style: normal;
      font-weight: 700;
      text-transform: uppercase;
      color: $primary-blue;
      text-decoration: none;
      
      @include range-screen(tiny, small) {
        font-size: 24px;
      }
      
      @include range-screen(medium, large) {
        font-size: 28px;
      }

      svg {
        vertical-align: top;
        fill: $primary-red;
      }
    }
  }
  .TrendingStandardsItemDescription {
    font-family: $font-family-primary;
    font-style: normal;
    color: #333738;
    font-size: 18px;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    min-height: 80px;
    overflow: hidden;
    line-height: 27px;
  }
  .TrendingStandardItemHeading {
    font-style: normal;
    font-weight: bolder;
    color: $primary-blue;
    font-size: 24px;
    margin: 0;
  }
  .site-search-search-button {
    .viewDetails:hover {
      .MuiButton-label {
        a {
          outline: none;
          color: white !important;
        }
      }
    }
    .MuiButton-label {
      a {
        outline: none;
      }
    }
  }
  .MuiGrid-container {
    display: flex !important;
    width: 100%;
  }
  .MuiGrid-grid-md-4 {
    flex-grow: 0;
    max-width: 33.333333%;
    flex-basis: 34% !important;

    .trendItem {
      padding: 1em;
      border: 2px solid grey;
    }
    .MuiPaper-root {
      outline: none;
      box-shadow: none;
      padding: 1em 4em;
    }
  }
  .MuiButtonBase-root {
    color: #17457a;
    border: 3px solid #17457a;
    padding: 0.5em 2.5em;
    font-size: 18px;
    background: white;
    font-weight: bold;
    border-radius: 50px;
    .MuiButton-label {
      a {
        text-decoration: none;
        color: #17457a;
      }
    }
  }
}
