@import '../../assets/styles/mixins';
@import '../../assets/styles/variables';
@import '../../assets/styles/typography';

.HeroBanner {
  color: #17457a !important;

  .TextColumn {
    position: absolute;
    padding: 5em 10em;
    @include max-screen(medium) {
      padding: 1em 0.5em;
    }
    .HeroHeading {
      font-size: 3.375rem;
      font-weight: bolder;
      margin-left: 0 !important;
      font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
        'Noto Sans', 'Liberation Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
        'Segoe UI Symbol', 'Noto Color Emoji';
      @include max-screen(medium) {
        @include font-size(2.375);
      }
    }
  }

  .ImageContainer {
    img {
      width: fit-content !important;
      width: 100% !important;
      height: 631px;
      @include max-screen(medium) {
        height: 389px;
      }
    }
  }
  .HeroAdditionalText {
    font-size: 24px;
    @include max-screen(medium) {
      @include font-size(2);
      line-height: 141.5% !important;
    }
    @include range-screen(tiny, small) {
      width: 80%;
    }
    @include range-screen(small, medium) {
      width: 75%;
    }
    @include range-screen(medium, large) {
      width: 75%;
    }
    @include min-screen(large) {
      width: 90%;
    }
  }

  .search-field-container {
    @include range-screen(small, medium) {
      width: 100% !important;
    }

    @include min-screen(large) {
      width: 80%;
    }
  }
}
