@import '_mixins.scss';
@import url('https://use.typekit.net/oog5rbx.css');
@import '_variables.scss';

// Font Mixins =============================================== *

@mixin heading-font {
  font-family: $font-family-primary;
  font-weight: 700;
  color: $primary-blue;
}

@mixin body-font {
  font-family: $font-family-primary;
  font-weight: normal;
  color: $cool-grey-11;
}

@mixin h1 {
  @include heading-font();
  @include font-size(3.6); //mobile
  margin-left: 18px;
  @media (min-width: 1263px) {
    margin-left: 40px;
  }
  @media (min-width: 1920px) {
    margin-left: 100px;
  }

  @include min-screen(large) {
    @include font-size(5.2);
  }

  @include min-screen(xlarge) {
    @include font-size(6.4);
  }
}

@mixin h2 {
  @include heading-font();
  @include font-size(2.8); //mobile

  @include min-screen(medium) {
    @include font-size(4.2);
  }

  @include min-screen(xlarge) {
    @include font-size(5.2);
  }
}

@mixin h3 {
  @include heading-font();
  @include font-size(2.4); //mobile

  @include min-screen(medium) {
    @include font-size(3.6);
  }

  @include min-screen(xlarge) {
    @include font-size(4.2);
  }
}

@mixin h4 {
  @include heading-font();
  @include font-size(2.2); //mobile

  @include min-screen(medium) {
    @include font-size(2.8);
  }

  @include min-screen(xlarge) {
    @include font-size(3.6);
  }
}

@mixin h5 {
  @include heading-font();
  @include font-size(2); //mobile
  margin-bottom: 18px;

  @include min-screen(medium) {
    @include font-size(2.2);
  }

  @include min-screen(xlarge) {
    @include font-size(2.8);
  }
}

@mixin h6 {
  @include heading-font();
  @include font-size(1.8); //mobile

  @include min-screen(medium) {
    @include font-size(1.8);
  }

  @include min-screen(xlarge) {
    @include font-size(2.2);
  }
}

body {
  @include body-font();
  @include font-size(2);

  @include min-screen(medium) {
    @include font-size(1.8);
  }

  @include min-screen(xlarge) {
    @include font-size(2.4);
  }
}

h1 {
  @include heading-font();
  @include font-size(3.6); //mobile
  margin-left: 18px;
  @media (min-width: 1263px) {
    margin-left: 100px;
  }
}

h2 {
  @include heading-font();
  @include font-size(2.8); //mobile
}

h3 {
  @include heading-font();
  @include font-size(2.4); //mobile
}

h4 {
  @include heading-font();
  @include font-size(2.2); //mobile
}

h5 {
  @include heading-font();
  @include font-size(2); //mobile
  margin-bottom: 18px;
}

h6 {
  @include heading-font();
  @include font-size(1.8); //mobile
}

a {
  color: $accent-blue;
  text-decoration: underline;
}

strong,
b {
  font-weight: 700;
}

ul {
  li {
    &::marker {
      color: $primary-blue;
    }
  }
}
