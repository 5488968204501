@import '../../assets/styles/_mixins.scss';
@import '../../assets/styles/_variables.scss';
@import '../../assets/styles/_typography.scss';

.cartdisclaimer {
  padding: 2em 5em 2em 5em;
  margin: 2% 10% 2% 10%;
  .disclaimertext1 {
    color: $black;
    font-family: $font-family-primary;
    font-size: 13px;
    font-weight: 400;
  }
  .privacypolylink {
    padding-left: 0.3em;
    padding-right: 0.3em;
  }
}