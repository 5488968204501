@import '../../assets/styles/_mixins.scss';
@import '../../assets/styles/_variables.scss';
@import '../../assets/styles/_typography.scss';

.refunded-amt{
  color: $primary-red !important;
  font-weight: 500 !important;
}

.refunded-amt-total{
  color: $primary-red !important;
}

.line-item-data{
  font-weight: bold !important;
}

.partial-refund-content-section{
  display: flex !important;
  flex-direction: column !important;
  .partial-return-text{
    font-size:0.75em !important;
    font-weight: 500 !important;
  }
}

.refundAdditionalText {
  margin-top: 2rem;
  @include max-screen(medium) {
    padding: 0em 3em !important;
    padding-bottom: 2rem !important;
  }
}

.text-bold{
  font-weight: bold;
}

.refund-reason {
  margin: 0 !important;
  font-size: 1.75rem;
  span{
    font-weight: 100 !important;
  }
}
