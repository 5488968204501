@import '../../assets/styles/_mixins.scss';
@import '../../assets/styles/_variables.scss';
@import '../../assets/styles/_typography.scss';

.subscriptionDialog {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: 0px;
  padding: 0px;
  @include range-screen(tiny, small) {
    width: 90% !important;
  }
  .MuiDialogContent-root {
    .subKeyDiv {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      align-content: center;
      gap: 1rem;
      .subKeyTitle {
        display: flex;
        flex-direction: row;
        align-items: center;

        .MuiIconButton-root {
          .MuiIconButton-label {
            .MuiSvgIcon-root {
              font-size: 2.5rem !important;
              color: $primary-blue !important;
            }
          }
        }
        .MuiIconButton-root:hover {
          background: none !important;
        }
      }

      .inputtext {
        border: 1px solid #c7c9c8;
        padding: 0.75em;
      }
    }
  }
  .error {
    color: $primary-red;
    font-size: 12px;
    width: 90%;
    line-height: 16px;
    width: 250px;
  }
  .ws-primary-button-no-border:disabled {
    background-color: $lightgrey !important;
    color: $white !important;
    border: 1px solid $grey !important;
  }
  .MuiDialogActions-root {
    gap: 1.5rem !important;
    padding: 0px !important;
    button {
      width: 45% !important;
    }
  }
  .MuiDialogContent-dividers {
    border: none !important;
  }
  .MuiDialogActions-spacing {
    padding: 0px 8px 0px 8px !important;
  }
}

.subscriptionDialogPaper {
  border: 0;
  outline: none;
  position: fixed;
  box-shadow: 0px 11px 15px -7px rgb(0 0 0 / 60%), 0px 24px 38px 3px rgb(0 0 0 / 30%),
    0px 9px 46px 8px rgb(0 0 0 / 40%) !important;
  border-radius: 6px;
  background-color: #fff;
  padding: 1rem;
}
.header-main {
  @include font-size(1);
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 1009;
  .PrimaryNavigation {
    .logo {
      img {
        min-width: 80px;
        vertical-align: middle;
      }
    }
  }
  .grow {
    flex-grow: 1;
    .navHeight {
      height: 100px;
      @include min-screen(large) {
        height: 140px;
      }
      @include max-screen(small) {
        height: 60px;
      }
      .header {
        background: #17457a !important;
        box-shadow: $none !important;
        align-content: flex-start;
        position: unset !important;
        padding: 0 !important;

        &.Subscribed {
          background: #0094d9 !important;
        }

        @include min-screen(large) {
          position: relative;
          overflow: hidden;
        }
        span {
          color: #fff;
        }
        .toolbar {
          min-height: 100px;
          align-items: center;
          justify-content: flex-end;
          @include min-screen(large) {
            position: relative;
          }
          @include range-screen(small, large) {
            position: relative;
          }
          @include max-screen(small) {
            padding-left: 2%;
            position: relative;
            min-height: 60px;
          }
          .logoImage {
            justify-self: flex-start;
            width: 60px;
            @include min-screen(small) {
              width: 400px;
              padding: 0 2%;
              // margin-right: 36px;
            }
            img {
              width: auto;
              max-width: unset;
              height: 40px !important;
              @include range-screen(tiny, small) {
                height: 30px !important;
              }
              @include range-screen(small, large) {
                height: 45px !important;
              }
              @media (min-screen: 1340px) {
                height: 50px !important;
              }
            }
          }

          .sectionDesktop {
            display: none;
            .subscribedUserLink {
              color: #fff;
              text-decoration: none;
              padding: 0 1.5em;
              font-size: 1.8rem;
              font-weight: 400;
              outline: none;
              white-space: nowrap;
              @media (min-width: 1280px) {
                text-wrap: wrap;
                display: flex;
                line-height: 1.2em;
                text-align: center;
              }
            }

            @include min-screen(large) {
              display: flex;
              justify-content: flex-end;
              flex-grow: 1;
              min-height: 100px;
              align-items: center;
            }
            .result-footer a,
            a:visited,
            a:hover {
              color: $white;
            }
            .LoginItem {
              display: flex;
              align-items: center;

              .verticalline {
                height: 28px;
                border: 1px solid #fff;
              }
            }
            .CartItem {
              margin: 0 1em;
            }
          }

          .sectionMobile {
            display: flex;
            justify-content: flex-end;
            flex-grow: 1;
            min-height: 56px;
            align-items: center;
            padding-right: 2%;
            @include min-screen(large) {
              display: none;
            }

            .MobileLoginItem {
              width: max-content !important;

              .login {
                padding: 0rem 1rem !important;
                .loginLink {
                  display: flex !important;
                  @include range-screen(tiny, small) {
                    .loginText {
                      display: none !important;
                    }
                    line-height: 0;
                    font-size: 0;
                    color: transparent;
                  }
                }
              }
            }

            .MobileCartItem {
              .cart {
                padding: 0rem 1rem;
                a {
                  display: block !important;
                  @include range-screen(tiny, xlarge) {
                    .cartText {
                      display: none !important;
                    }
                    line-height: 0;
                    font-size: 0;
                    color: transparent;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .secondary-menu-div {
    display: none;
    @include min-screen(large) {
      display: block;
      width: 100%;
      height: 40px;
      background-color: #e9ebea;
      box-shadow: inset 0 0 4px grey;
    }
    .secondarymenu {
      display: none;
      nav {
        align-items: center;
        justify-content: center;
        display: flex;
        gap: 2rem;
        line-height: 28px;

        a {
          color: $primary-blue;
          text-decoration: none;
          padding-right: 1rem;
          @include font-size(1.8);
          font-weight: 700;
          outline: none;
          white-space: nowrap;
        }
        .subscribedUserLink {
          padding-left: 1.2em;
        }
      }
      span {
        height: 28px;
        border: 0.8px solid $white;
      }
      @include min-screen(large) {
        display: flex;
        padding: 5px 0px;
        margin-left: 1rem;
        justify-content: left;
        align-items: center;
      }
    }
  }
}
