@import '../../assets/styles/_mixins.scss';
@import '../../assets/styles/_variables.scss';
@import '../../assets/styles/_typography.scss';

.orderSummarySticky {
  position: sticky;
  position: -webkit-sticky;
  top: 0;
}

.orderSummary {
  @include range-screen(tiny, medium) {
    padding: 0em 1em;
    width: 95%;
  }
  @include min-screen(medium) {
    padding: 0em 1.5em 0em 3.5em;
  }
  h1 {
    margin: 0 !important;
    padding-top: 1em;
    font-family: $font-family-primary;
    padding-bottom: 0.75em;
    @include range-screen(tiny, large) {
      padding-top: 0em;
    }
  }
  .orderSummaryEditCart {
    padding-top: 1em;
    font-weight: 700;
    font-family: $font-family-primary;
    svg {
      color: #2b7bb9;
      font-size: 2.5rem;
    }
    a {
      vertical-align: super;
      padding-left: 0.5em;
    }
  }
  .orderPriceDetails {
    background: #17457a;
    display: flex;
    flex-direction: column;
    p {
      margin: 2rem;
    }

    .orderSubTotal {
      display: flex;
      grid-gap: 5em;
      font-family: $font-family-primary;
      border-bottom: 1px solid #fff;
      justify-content: space-between;
      .orderSubTotalDetail {
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 22px;
        color: #ffffff;
        font-family: $font-family-primary;
      }
    }
    .orderSalesTax {
      display: flex;
      font-family: $font-family-primary;
      border-bottom: 1px solid #fff;
      justify-content: space-between;
      .orderSalesTaxDetail {
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 22px;
        color: #ffffff;
        font-family: $font-family-primary;
      }
    }
    .orderShippingAndHandling {
      display: flex;
      font-family: $font-family-primary;
      border-bottom: 1px solid #fff;
      justify-content: space-between;
      .orderShippingDetail {
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 22px;
        color: #ffffff;
        font-family: $font-family-primary;
        width: -webkit-fill-available;
      }
      .shippingDetailNoWrap {
        white-space: unset;
        text-align: right;
      }
    }
    .orderMemberDiscount {
      display: flex;
      flex-direction: row;
      color: #fff;
      color: #cd163f;
      background: #e9ebea;
      justify-content: space-between;
      font-family: $font-family-primary;
      .orderMemberDiscountTitleSection {
        display: flex;
        p {
          font-weight: bold;
          font-family: $font-family-primary;
        }
        .tooltip {
          .tooltipArea {
            padding: 0;
            padding-top: 1em !important;
            padding-left: 0.25em;
            .MuiButton-root {
              .MuiButton-label {
                .MuiSvgIcon-root {
                  font-size: 2.5rem;
                }
              }
            }
          }
        }
      }
    }
    .orderTotal {
      font-style: normal;
      font-weight: 700;
      font-size: 36px;
      line-height: 43px;
      color: #ffffff;
      justify-content: space-between;
      font-family: $font-family-primary;
      .orderTotalSection,
      .orderButtonSection {
        text-align: center;
        font-size: 18px;
        a {
          text-decoration: none !important;
        }

        button {
          width: 80%;
          height: 3em;
        }
      }
      .orderTotalSection {
        display: flex;
        font-size: 36px;
        p {
          font-family: $font-family-primary;
        }
      }
      .orderButtonSection {
        display: flex;
        flex-direction: column;
        gap: 1em;
        padding-bottom: 3rem;
      }
    }
  }
  .optInDiv {
    padding: 1.5em 0em;
    .subscriptionNotifications {
      padding: 1.3em 1em;
      background: #e9ebea;
      border: 1px solid #c7c9c8;
      display: flex;
      gap: 0em;
      .subscriptionNotificationText {
        font-family: $font-family-primary;
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 22px;
        color: $primary-blue;
        display: flex !important;
        flex-direction: column;
        .subscriptionHeading {
          font-style: normal;
          font-weight: 700;
          font-size: 18px;
          line-height: 22px;
          color: #17457a;
          margin: 0 !important;
          padding-bottom: 0.5em;
        }
      }
      .tooltip {
        .MuiButton-root {
          padding: 0 !important;
          min-width: 24px;
        }
        .MuiSvgIcon-root {
          font-size: 2.125rem;
        }
      }
    }
  }
}
