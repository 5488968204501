@import '../../assets/styles/_variables.scss';

.loading-style {
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.loader {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: #fff;
  box-shadow: 32px 0 #fff, -32px 0 #fff;
  position: relative;
  animation: flash 0.5s ease-out infinite alternate;
}

@keyframes flash {
  0% {
    background-color: $primary-blue;
    box-shadow: 32px 0 $primary-blue, -32px 0 #fff;
  }
  50% {
    background-color: #fff;
    box-shadow: 32px 0 $primary-blue, -32px 0 $primary-blue;
  }
  100% {
    background-color: $primary-blue;
    box-shadow: 32px 0 #fff, -32px 0 $primary-blue;
  }
}
