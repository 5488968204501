@import '../../assets/styles/_mixins.scss';
@import '../../assets/styles/_variables.scss';
@import '../../assets/styles/_typography.scss';

.mobileDrawer .MuiDrawer-paper {
  top: 91px !important;
  width: 100%;
  height: calc(100% - 91px);
  padding: 0 !important;

  @include max-screen(small){
    top: 60px !important;
    height: calc(100% - 60px);
  }
}

.mobileDrawer .MuiBox-root {
  min-width: 100%;
}

.MobileNavigation {
  font-size: 18px !important;
  .MuiSvgIcon-root {
    font-size: 30px;
  }
  .MuiIconButton-root {
    margin-left: 0 !important;
  }

  .mobileDrawerBox {
    display: block;
    .CloseMenuDiv {
      .CloseMobNavMenu {
        width: 100%;
        display: block;
        padding: 1em;
        font-size: 1.5rem !important;
        color: black !important;
        text-decoration: none !important;
        border-top: 1px solid grey;
      }
    }
  }
}
