.cartSliderHR {
  border: 0.25px solid #c7c9c8;
  width: 90%;
  margin-top: 20px;
}
.cartLoadingSection {
  width: 637.25px;
}
.cartSliderClose {
  flex: 0 0 auto;
  color: rgba(0, 0, 0, 0.54);
  padding: 12px;
  padding-top: 20px;
  font-size: 3.085714285714286rem;
  transition: none !important;
  border-radius: 0% !important;
  width: fit-content;
  display: flex !important;
  align-self: end;
  .MuiIconButton-label {
    width: 100%;
    display: block;
    align-items: inherit;
    justify-content: inherit;
    .MuiSvgIcon-root {
      transition: none !important;
    }
  }
}
