.changepassword {
  vertical-align: middle;

  a {
    margin-left: 1em;
    font-family: 'urw-din' !important;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    -webkit-text-decoration-line: underline;
    text-decoration-line: underline;
    color: #2b7bb9;
  }
}

