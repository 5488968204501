@import '../../assets/styles/_mixins.scss';
@import '../../assets/styles/_variables.scss';
@import '../../assets/styles/_typography.scss';

.marketingPromoArea {
  background: $white;
  box-sizing: border-box;
  .marketingImage {
    @include range-screen(tiny, small) {
      width: 100%;
      height: auto;
      padding: 0;
    }
    @include range-screen(small, medium) {
      width: 100%;
      width: -webkit-fill-available; /* Mozilla-based browsers will ignore this. */
      width: -moz-available; /* WebKit-based browsers will ignore this. */
    }
    @include range-screen(medium, large) {
      width: 100%;
      width: -webkit-fill-available; /* Mozilla-based browsers will ignore this. */
      width: -moz-available; /* WebKit-based browsers will ignore this. */
    }
    @include min-screen(large) {
      width: 100%;
      width: -webkit-fill-available; /* Mozilla-based browsers will ignore this. */
      width: -moz-available; /* WebKit-based browsers will ignore this. */
    }
  }

  .ImageAreaContainer {
    padding: 0em;
    line-height: 0;
    align-items: flex-end;
    .MuiGrid-container {
      @include min-screen(medium) {
        display: table;
      }

      .ImageGridItem {
        @include min-screen(medium) {
          display: table-cell;
        }

        img {
          object-fit: cover;
        }
      }
    }
    .ImageGridItemCollect {
      display: flex;
      flex-direction: row;
    }
  }
  .MarketingPromoHeading {
    font-style: normal;
    font-weight: bolder;
    color: #cd163f;
    white-space: nowrap;
    margin: 0 !important;
    @include range-screen(tiny, medium) {
      padding: 0.5em 1em;
      font-size: 36px;
    }

    @include min-screen(medium) {
      font-size: 44px;
      padding: 0.5em 1.5em;
    }
  }
  .MarketingPromoDescription {
    font-style: normal;
    line-height: 29px;
    color: #333738;
    margin: 0 !important;

    @include range-screen(tiny, medium) {
      padding: 0 2em;
      font-size: 18px;
    }
    @include min-screen(medium) {
      padding: 0 3.75em;
    }
  }
  .site-search-search-button {
    @include range-screen(tiny, medium) {
      padding: 1.5em 2em;
      font-size: 18px;
    }

    @include min-screen(medium) {
      padding: 1.5em 3.75em;
    }
    .siteSearchButton {
      span {
        a {
          color: $primary-blue;
          text-decoration: none !important;
        }
      }
    }
    .siteSearchButton:hover {
      .MuiButton-label {
        a {
          outline: none;
          color: white !important;
          text-decoration: none !important;
        }
      }
    }
  }
}
