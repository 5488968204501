@import '../../assets/styles/_mixins.scss';
@import '../../assets/styles/_variables.scss';
@import '../../assets/styles/_typography.scss';

.optinnotification {
  @include range-screen(tiny, small) {
    padding: 0em 1.5em !important;
  }
  @include range-screen(small, medium) {
    padding: 0em 3.5em !important;
  }
  @include min-screen(medium) {
    padding: 1em 4em !important;
  }
  .errormessage {
    font-weight: 400;
    color: $alert-red;
    font-size: 13px;
  }
  .optinnotificationdiv {
    min-width: 50%;

    .notify-email-group {
      display: flex;
      flex-wrap: wrap;
      gap: 1em;
      align-items: center;
    }
    .inputtext {
      padding: 1em;
      min-width: 50%;
      color: $textinput;
      font-size: 15px;
      font-weight: 400;
      border: 1px solid inherit;
      margin-bottom: 5px;
    }

    input[type='email']:invalid {
      border: 2px solid $alert-red;
    }
  }
  .tooltip {
    padding: 0 !important;
    margin: 0 !important;
    height: fit-content;
    .tooltipArea {
      margin-top: -1px;
      margin-left: 3px;
      .MuiButton-root {
        padding-top: 0.4em;
        .MuiButton-label {
          .MuiSvgIcon-root {
            font-size: 2.5rem;
            padding-bottom: 5px;
          }
        }
      }
    }
  }
  .optinnotificationtext {
    font-family: $font-family-primary;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 22px;
    color: $primary-blue;
    display: inline-block;
    padding-right: 0.2em;
    div {
      display: inline-block;
    }
  }

  .emailtextfield {
    font-family: $font-family-primary !important;
    color: $cool-grey-8 !important;
    font-style: normal;
    font-weight: 400 !important;
    font-size: 16px !important;
    line-height: 22px;
    width: 50vw;
    border: 1px solid $cool-grey-5 !important;
    background: $white !important;
    padding: 0.5em 0 0.5em 0.5em;
    margin-right: 2%;
  }
  .emailtextfielderror {
    font-family: $font-family-primary !important;
    color: $cool-grey-8 !important;
    font-style: normal;
    font-weight: 400 !important;
    font-size: 16px !important;
    line-height: 22px;
    width: 50vw;
    border: 1px solid $alert-red !important;
    background: $white !important;
    padding: 0.5em 0 0.5em 0.5em;
    margin-right: 2%;
  }
  .emailtextfield:focus {
    outline: none !important;
    box-shadow: none !important;
  }
  .btnnotifyme {
    font-family: $font-family-primary !important;
    font-weight: 700 !important;
    font-size: 16px !important;
    line-height: 22px !important;
    color: $primary-blue !important;
    border: 2px solid $primary-blue !important;
    border-radius: 35px !important;
    padding: 0.5em 1em 0.5em 1em !important;
    width: 175px;
    span {
      white-space: nowrap !important;
    }
  }
  .btnnotifyme:focus {
    outline: none;
    background: transparent;
    box-shadow: none;
  }
  .questionicon {
    font-style: normal;
    font-weight: 100 !important;
    font-size: 11px;
    color: $white;
    padding-bottom: 0.2em;
    position: relative;
    bottom: 2px;
  }
  .optindiv {
    background: $lily-white;
    padding-top: 1em;
    padding-bottom: 1em;
    padding-left: 0.5em;
    display: flex;
    border: 1px solid $cool-grey-5;
    align-items: center;
  }
  .optindiv1 {
    background: none;
    padding-top: 1em;
    padding-bottom: 0.5em;
    display: flex;
  }
  .disclaimertext {
    color: $black;
    font-family: $font-family-primary;
    font-size: 13px;
    display: block;
    font-weight: 400;
  }
  .disclaimertext1 {
    color: $black;
    font-family: $font-family-primary;
    font-size: 13px;
    font-weight: 400;
  }
  .checkbox {
    outline: none !important;
    color: $black;
  }
  input:checked {
    accent-color: $white;
  }
  .privacypolylink {
    padding-left: 0.3em;
    padding-right: 0.3em;
  }
}
