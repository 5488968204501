.drilldownmenu {
  color: purple;
}

.drilldown-body {
  nav {
    display: flex;
    flex-direction: column;
    margin-top: -5px !important;

    a {
      border-top: 1px solid grey;
      padding: 1em;
      font-size: 1.5rem !important;
    }
  }
}
