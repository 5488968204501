@import '../../assets/styles/_mixins.scss';
@import '../../assets/styles/_variables.scss';

.shippinginformation {
  background: none;

  .shippinginfoheader {
    color: $primary-blue;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    font-family: $font-family-primary;
    display: flex;
    justify-content: space-between;
  }
  .shippinginfoheaderdetail {
    color: $black;
    font-weight: 400;
    font-size: 16px;
    font-family: $font-family-primary;
    padding-left: 0.5em;
  }
  .addressinfoheader {
    color: $black;
    font-style: normal;
    font-weight: 700;
    font-family: $font-family-primary;
    font-size: 18px;
  }
  .po-disclaimer {
    font-size: 16px;
  }
  .firstnamediv {
    float: left;
    width: 40%;
  }
  .lastnamediv {
    float: left;
    width: 47%;
  }
  .maindiv {
    padding-left: 3.5em;
    padding-top: 0.5em;
    .addnewaddressbtn {
      padding: 0.8em 3em 0.8em 3em !important;
      width: 35%;
      border-radius: 30px;
      background: $primary-blue;
      font-size: 16px;
      color: $white;
      font-weight: 700;
      border: none;
      margin-top: 2%;
      cursor: pointer;
    }
  }
  .inputtext {
    width: 100%;
    color: $black;
    font-size: 16px;
    font-weight: 400;
  }
  .inputtext:focus {
    outline: none;
  }
  .alignAddress {
    @include min-screen(xmedium) {
      display: flex;
      gap: 1em;
    }
  }

  .unitdiv {
    @include min-screen(xmedium) {
      width: 33%;
      margin-right: 5%;
    }
  }
  .citydiv {
    @include min-screen(xmedium) {
      width: 15%;
      margin-right: 5%;
    }
  }
  .stdiv {
    @include min-screen(xmedium) {
      width: 18%;
      margin-right: 2%;
    }
  }
  .postaldiv {
    @include min-screen(xmedium) {
      width: 18%;
    }
  }
  .changelink {
    font-size: 16px;
  }
}
.addresslistpopupdialog {
  .MuiButton-root {
    background: none;
    background-color: $white !important;
    color: inherit;
    border: none !important;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
    .MuiButton-label {
      font-family: $font-family-primary;
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 22px;
      text-decoration-line: underline;
      color: $accent-blue;
    }
    .addresslistpopupbuttons {
      .MuiDialog-container .MuiDialogActions-root {
        justify-content: left !important;
      }
      .MuiDialog-container .MuiPaper-rounded .MuiDialogActions-root {
        gap: 3em !important;
      }
      .buttonse {
        width: 50px;
      }
    }
  }
}
.addresslistpopupbuttons {
  .MuiDialog-container .MuiDialogActions-root {
    justify-content: left !important;
  }
  .MuiDialog-container .MuiPaper-rounded .MuiDialogActions-root {
    gap: 3em !important;
  }
  .buttonse {
    width: 50px;
  }
}
.MuiPaper-elevation24 {
  box-shadow: 0px 11px 15px -7px rgb(0 0 0 / 60%), 0px 24px 38px 3px rgb(0 0 0 / 30%),
    0px 9px 46px 8px rgb(0 0 0 / 40%) !important;
}

.addresslist {
  font-family: $font-family-primary;
  .addressheader {
    font-weight: 700;
    color: $black;
  }

  .radiobutton {
    height: 17px;
    width: 20px;
    position: relative;
    top: 2px;
    border: 14px solid $black;
    margin-left: 1%;
  }
  .faphoneicon {
    color: $cool-grey-5;
    padding-right: 2px;
    font-size: 15px;
  }
  .addressdetailtext {
    line-height: 35px;
  }
}
