@import '../../assets/styles/_variables.scss';

.creditcard {
  .creditcard-form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    font-weight: bold;

    .creditcard-form-row-group {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      gap: 2rem;

      .text-error {
        color: $primary-red;
        font-size: 1.2rem;
        font-weight: normal;
      }
    }

    .creditcard-form-row {
      display: flex;
      flex-direction: column;
      width: 100%;
      .creditcard-form-row-header {
        font-size: 1.6rem;
        display: flex;
        justify-content: space-between;
      }
    }

    .creditcard-form-column {
      display: flex;
      flex-direction: row;
      gap: 1rem;
      justify-content: space-evenly;
    }

    .text-error {
      color: $primary-red;
      font-size: 1.2rem;
      font-weight: normal;
    }

    input,
    select {
      padding: 1rem 0rem 1rem 1rem;
      border: 1px solid $light-grey;
    }

    input:focus,
    select:focus {
      outline: none;
    }
  }

  .available-creditcards {
    display: flex;
    gap: 15px;

    .creditcardimage {
      position: relative;
      height: 35px;
      border: 1px solid lightgrey;
      border-radius: 3px;
    }

    .ccdisabled {
      opacity: 0.2;
    }
  }

  .creditdiv {
    margin-top: 1em;
    .addressinfoheader {
      color: $black;
      font-style: normal;
      font-weight: 700;
      font-family: $font-family-primary;
      font-size: 18px;
      display: inline-block;
      padding-top: 1em;
      padding-right: 7em;
    }
    .expirySection {
      display: flex;
      justify-content: space-between;
      .tooltip {
        padding-top: 1em;
      }
      .expiryheader {
        color: $black;
        font-style: normal;
        font-weight: 700;
        font-family: $font-family-primary;
        font-size: 18px;
        display: inline-block;
        padding-top: 1em;
      }
    }

    .ccvheader {
      color: $black;
      font-style: normal;
      font-weight: 700;
      font-family: $font-family-primary;
      font-size: 18px;
      display: inline-block;
      padding-top: 1em;
    }
    .questionicon {
      font-style: normal;
      font-weight: 100 !important;
      font-size: 10px;
      color: $white;
      padding-bottom: 0.4em;
      padding-left: 0.1em;
      position: relative;
      bottom: 2px;
      right: 1px;
    }

    .errormessage {
      font-weight: 400;
      color: $alert-red;
      font-size: 13px;
    }
    .tooltiptitle {
      display: inline-block;
    }
    .available-creditcards {
      display: flex;
      gap: 15px;
    }

    .creditcardimage {
      position: relative;
      height: 35px;
      border: 1px solid lightgrey;
      border-radius: 3px;
    }
    .ccdisabled {
      opacity: 0.2;
    }
    .creditcardtext {
      width: -webkit-fill-available;
      height: 40px;
    }
    .expiryccvdiv {
      display: flex;
      width: -webkit-fill-available;
      gap: 1em;
      button {
        margin-right: 10px;
      }
      .expiry {
        display: flex;
        flex-direction: column;
        width: 50%;
        .expiryheader {
          color: $black;
          font-style: normal;
          font-weight: 700;
          font-family: $font-family-primary;
          font-size: 18px;
          display: inline-block;
          padding-top: 1em;
        }
      }
      .cvc {
        display: flex;
        flex-direction: column;
        width: 50%;
        .ccvSection {
          .ccvTitleSection {
            display: flex;
            .tooltip {
              padding-top: 0.8em;
              padding-left: 0.25em;
              .MuiSvgIcon-root {
                font-size: 2.2rem;
              }
            }
          }
        }
      }
      .expiryccvtext {
        // width: 80%;
        height: 40px;
      }
    }
  }
}
