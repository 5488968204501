@import '../../assets/styles/_mixins.scss';
@import '../../assets/styles/_variables.scss';
@import '../../assets/styles/_typography.scss';

.recipientemail {
  margin-top: 3rem;
  .emaildiv {
    display: flex;
    .tooltip {
      padding: 0 !important;
      margin: 0 !important;
      height: fit-content;
      .tooltipArea {
        margin-top: -6px;
        margin-left: 6px;
        .MuiButton-root {
          padding-top: 0.4em;
          .MuiButton-label {
            .MuiSvgIcon-root {
              font-size: 2.5rem;
            }
          }
        }
      }
    }
  }
  .inputtext {
    padding: 1em;
    width: 90%;
    color: $textinput;
    font-size: 15px;
    font-weight: 400;
    border: 1px solid inherit;
    margin-bottom: 5px;
  }

  input[type='email']:invalid {
    border: 1px solid $alert-red;
  }

  input[type='email']::placeholder {
    overflow: visible;
  }

  .tooltipArea {
    margin-top: -9px;
    .MuiButton-root {
      padding-top: 0.4em;
      color: $accent-blue;
      min-width: 50px;
      .MuiButton-label {
        .MuiSvgIcon-root {
          font-size: 2.5rem;
        }
      }
    }
    .MuiButton-text {
      padding-left: 0px !important;
    }
    .MuiTooltip-tooltip {
      color: $white;
      font-size: 1.125rem;
    }
    .MuiTooltip-tooltip span {
      background-color: transparent !important;
      color: $white !important;
    }
    .MuiTooltip-tooltip strong {
      background-color: transparent !important;
    }
  }
  .emailheader {
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    color: $primary-blue;
  }

  .plusicon {
    color: $accent-blue;
    font-size: 14px;
    padding-right: 2px;
    position: relative;
    top: 2px;
  }
  .addemaillink {
    color: $accent-blue;
    font-size: 14px;
    font-weight: 700;
    cursor: pointer;
  }
  .fatimesicon {
    color: $cool-grey-5;
    font-weight: 400;
    font-size: 14px;
    position: relative;
    margin-left: -5%;
    cursor: pointer;
  }
}
