@import '../../assets/styles/_variables.scss';

.stdpreviewimage {
  color: #333738;
  height: 150px;
  width: 120px;
}
.previewimage {
  position: relative;
  width: 100%;
  height: 100%;
  img {
    width: 100%;
    height: 100%;
  }
}
.overlay {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0.7;
  transition: 0.3s ease;
  background-image: linear-gradient(transparent, transparent, gray);
}

.fasearchplus-icon {
  position: absolute;
  color: white;
  font-size: 2rem;
  cursor: pointer;
  bottom: 0;
  right: 0;
  font-weight: bolder;
}
