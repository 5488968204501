@import '../../assets/styles/_mixins.scss';
@import '../../assets/styles/_variables.scss';

.subscriptionlanguageoptions {
  .subscription-language-section {
    padding: 1em 4em;
    display: flex;
  }
  .languages-list {
    display: flex;
    flex-direction: column;
    gap: 1em;
    margin: 0em 2em 0em 1.5em;
    padding: 0em 2em 0em 0em;
  }
  .languages-list-width {
    max-width: 40%;
  }
  .language-div {
    gap: 1em;
    display: flex;
    vertical-align: middle;
    align-items: center;
    justify-content: space-between;
  }
  .language-section {
    gap: 1em;
    display: flex;
    flex-direction: column;
  }
  .ws-primary-button-no-border:disabled {
    background-color: $cool-grey-8 !important;
    border: 1px solid $cool-grey-4 !important;
    color: $lightgrey !important;
    pointer-events: none;
  }
  .download-error {
    color: red;
    padding: 0 !important;
    margin: 0 !important;
  }
}
