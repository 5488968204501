@import '../../assets/styles/_variables.scss';

.quantity {
  background: none;
}

.quantitymaindiv {
  height: fit-content;
  width: fit-content;
}

.MuiDialogTitle-root {
  background: $white;
  padding: 1em !important;
  h2 {
    font-size: 18px;
    color: $black;
    font-family: $font-family-primary;
    font-weight: 700;
  }
}

.MuiDialogActions-root {
  background: $white;
  button {
    background: $white;
    padding: 0.5em !important;
    border: 3px solid $primary-blue;
    border-radius: 25px;
    width: 45% !important;
    white-space: nowrap;
    font-size: 12px;
    font-weight: 700;
    color: $primary-blue;
  }
}
.MuiDialogActions-root button:focus {
  outline: none;
  background: $primary-blue;
  color: $white;
}
.MuiDialog-container {
  .MuiPaper-rounded {
    border-radius: 0%;
  }
  .MuiDialogActions-root {
    justify-content: center;
  }
}
