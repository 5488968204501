@import '../../assets/styles/_variables.scss';
@import '../../assets/styles/_typography.scss';

#alert-dialog-title {
  text-align: center;
}

.ws-modal-dialog {
  border-radius: 2rem;
  max-width: 550px;

  .MuiDialogTitle-root {
    padding: 0em !important;
    background-color: unset;

    .MuiTypography-root {
      font-family: $font-family-primary !important;
      font-weight: 600;
      padding: 0.5em 0.5em;
    }
  }
  .MuiButton-label {
    font-size: 1.6rem !important;
  }

  .company-form {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .company-form-scroller {
      height: 40vh;
      overflow-y: scroll;
      display: flex;
      flex-direction: column;
      gap: 2rem;
      padding-right: 0.5rem;
      .invalidHeader {
        background-color: $alert-red;
        padding: 0.75em 0em;
        margin-top: 0.5em;
        display: flex;
        .toastClose {
          font-size: 34px;
          cursor: pointer;
          vertical-align: middle;
          text-align: center;
          color: white;
          margin-right: 0.5em;
        }
        .invalidTextArea {
          padding: 0em 1em;
          .invalidAddressMsg {
            margin: 0 !important;
            color: $white;
            text-align: center;
          }
        }
      }
    }

    .company-form-row-group {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      gap: 2rem;

      .text-error {
        color: red;
        font-size: 0.9rem;
      }
    }

    .company-form-row {
      display: flex;
      flex-direction: column;
      .company-form-row-header {
        font-size: 1.6rem;
        display: flex;
        justify-content: space-between;
      }
      .countryNoteDiv {
        margin-top: 10px;
      }
      .countryNote {
        font-size: 1.3rem;
        line-height: 0;
      }
    }

    .company-form-column {
      display: flex;
      flex-direction: row;
      gap: 1rem;
      justify-content: space-evenly;
    }

    .text-error {
      color: red;
      font-size: 1.2rem;
    }

    input,
    select {
      padding: 1rem 0rem 1rem 1rem;
      border: 1px solid $light-grey;
    }

    input:focus,
    select:focus {
      outline: none;
    }
  }
}

.ws-modalTitle {
  font-style: normal;
  font-weight: 700;
  font-size: 2.4rem;
  color: $primary-blue;
  font-family: $font-family-primary;
  margin: 0 !important;
}

.MuiDialogActions-root {
  button {
    cursor: pointer;
  }
}

.newCompanyDialog {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: 0px;
  padding: 0px;
  @include range-screen(tiny, small) {
    width: 90% !important;
  }
  @include min-screen(small) {
    min-width: 500px;
  }
}

.newCompanyDialogPaper {
  border: 0;
  outline: none;
  position: fixed;
  box-shadow: 0px 11px 15px -7px rgb(0 0 0 / 60%), 0px 24px 38px 3px rgb(0 0 0 / 30%),
    0px 9px 46px 8px rgb(0 0 0 / 40%) !important;
  border-radius: 6px;
  background-color: #fff;
}

.accountInfo {
  .EditLink {
    font-weight: 700;
    svg {
      color: $accent-blue;
      padding-right: 5px;
      padding-bottom: 5px;
      vertical-align: middle;
    }
  }
  .accountInfoHeader {
    padding: 2em 4em !important;
    padding-bottom: 1em !important;
    @include range-screen(tiny, medium) {
      padding: 0 1em 1em 1em !important;
    }
    h1 {
      font-style: normal;
      font-weight: 700;
      font-size: 36px;
      line-height: 43px;
      color: $primary-blue;
      font-family: $font-family-primary;
      margin: 0 !important;
    }
  }

  .createNewCompany {
    background-color: $primary-blue !important;
    color: white !important;
    margin: 0 !important;
    float: left;
    padding: 0.5em 2em !important;
    border: 3px solid white !important;
    border-radius: 34px !important;
    font-family: $font-family-primary;
    font-weight: 700 !important;
    font-size: 1em;
    cursor: pointer;
    transition: 0.3s;
  }

  .accountInfoDetails {
    padding: 0 4em !important;
    @include range-screen(tiny, medium) {
      padding: 0 1em !important;
    }
    p {
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 29px;
      color: $primary-blue;
      margin-left: 0 !important;
    }

    .companyNameField {
      .MuiAutocomplete-endAdornment {
        top: unset !important;
        right: 9px;
      }
    }

    .company-title-div {
      display: flex;
      .tooltip {
        line-height: 0;
        margin-top: 18px;
        margin-left: 5px;
        svg {
          font-size: 2.125rem;
        }
      }
    }

    .profileDetailHeadings,
    .shippingHeadings,
    .billingHeadings {
      font-weight: 700;
      font-size: 18px;
      line-height: 22px;
      color: #333738;
    }
    .profileDetailText,
    .shippingDetailText,
    .billingDetailText,
    .notificationDetailText {
      font-weight: 400;
      font-size: 18px;
      line-height: 22px;
      color: #333738;
      margin: 0 !important;
      svg {
        font-size: 14px;
        padding-right: 5px;
      }
    }
    .profileDetailText {
      svg {
        font-size: 23px;
        vertical-align: bottom;
        padding-left: 5px;
      }
    }
    .profileDetailVerifiedText {
      font-weight: 400;
      font-size: 18px;
      line-height: 22px;
      color: #333738;
      padding-left: 0.5em;
      margin: 0 !important;
    }
    .profileDetails {
      .profileAlign {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        column-gap: 4.5em;
      }
      .personEmailDetails {
        .emailAddressField {
          height: 30px;
          width: 100%;
        }
        .EmailTitleSection {
          display: flex;
          .tooltip {
            line-height: 0;
            margin-top: 18px;
            margin-left: 5px;
            svg {
              font-size: 2.125rem;
            }
          }
        }
      }
      .profileCompany {
        .personProfileCompany {
          font-weight: 700;
          font-size: 16px;
          line-height: 22px;
          color: #333738;

          input:disabled {
            padding-left: 9px;
          }

          .companyNameField {
            @include max-screen(small) {
              width: 100%;
            }
            @include min-screen(small) {
              width: 50%;
            }

            font-size: 16px;
            height: 4rem;
            .MuiInputBase-root {
              font-size: 16px;
              height: 4.6rem;
            }
          }
        }

        .profileVerifiedCompany {
          padding: 1em;
          display: flex;
          flex-direction: column;
          gap: 0.5em;
          .nonAPIMember {
            display: flex;
            gap: 0.25em;
            .APIMemberCheck {
              height: 18px;
              width: 18px;
            }
            .tooltip {
              svg {
                font-size: 2.125rem;
              }
            }
          }
          .verifiedAPICompany {
            display: flex;
            flex-direction: row;
            margin-bottom: 0.2%;
            margin-top: 0.5%;
            svg {
              fill: #8dc926;
            }
          }
        }
      }
      .profileButtons {
        padding-top: 1em;
        display: flex;
        flex-direction: row;
        gap: 2em;
        .profileSave,
        .profileDisregard {
          padding: 0;

          width: 25rem;
          background: $primary-blue;
          border: 3px solid $primary-blue;
          border-radius: 34px;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          padding: 0.5em 2.5em;
          color: $white;
          white-space: nowrap;

          .MuiButton-label {
            font-family: $font-family-primary;
            font-style: normal;
            font-weight: 700;
            font-size: 18px;
            line-height: 22px;
            text-decoration-line: none;
            color: $white;
          }
        }

        .profileDisregard {
          color: $primary-blue;
          border: 3px solid $primary-blue;
          background-color: $white;
          .MuiButton-label {
            color: $primary-blue;
          }
        }
      }
      .expirestatus {
        color: $textinput;
        font-weight: 400;
        font-size: 16px;
        padding-left: 1.5em;
      }
      .expiresoonicon {
        color: $expiresoon !important;
      }
      .updatetaxexemlink {
        font-weight: 700;
        padding-left: 1em;
      }
      .expired {
        color: $primary-red;
        font-size: 16px;
        font-weight: 400;
        padding-left: 1.5em;
      }
      .expiredicon {
        color: $primary-red;
        font-size: 15px;
      }
    }

    .horizontalLine {
      border: 0.5px solid #c7c9c8;
    }
    //shipping section
    .shippingSection {
      .addnewbutton {
        .MuiButton-root {
          background: none;
          background-color: $white !important;
          color: inherit;
          border: none !important;
          padding: 0;
          font: inherit;
          cursor: pointer;
          outline: inherit;
          .MuiButton-label {
            font-family: $font-family-primary;
            font-style: normal;
            font-weight: 700;
            font-size: 18px;
            line-height: 22px;
            text-decoration-line: underline;
            color: $accent-blue;
          }
        }
      }
    }
    .shippingSection {
      .EditLink {
        font-weight: 700;
        position: relative;
        svg {
          color: $accent-blue;
          padding-right: 5px;
          padding-left: 15px;
          padding-bottom: 5px;
          vertical-align: middle;
        }
        a {
          position: relative;
        }
      }
    }
    .shippingSection,
    .billingSection,
    .notificationSection {
      align-items: center;
      padding-bottom: 2em;
      .shippingOther {
        .shippingAddressSection {
          .dialogdiv {
            .MuiTypography-root {
              text-align: center;
            }
          }
        }
      }
      .EditLink {
        font-weight: 700;
        svg {
          color: $accent-blue;
          padding-right: 5px;
          padding-left: 15px;
          padding-bottom: 5px;
          vertical-align: middle;
        }
        a {
          position: relative;
        }
      }
      .notificationheader {
        display: flex;
        align-items: center;
      }
      .notificationDetailText {
        font-family: $font-family-primary;
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        color: $black;
      }
      .billingOther {
        padding-top: 1em;
        padding-bottom: 1em;
      }
      .shippingDetails,
      .billingDetails {
        padding-bottom: 2em;
      }
      .shippingDetailMain,
      .billingDetailMain {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        gap: 0.9em;
        align-items: center;
        line-height: 2;
        @include range-screen(tiny, xmedium) {
          padding-bottom: 2rem;
        }
        .verticalline {
          height: 22px;
          border: 0.5px solid #333738;
          @include range-screen(tiny, xmedium) {
            display: none;
          }
        }
        .shippingDeleteLink,
        .billingEditLink,
        .billingDeleteLink {
          font-weight: 700;
          position: relative;
          cursor: pointer;
          svg {
            color: $accent-blue;
            padding-right: 5px;
            padding-bottom: 5px;
            vertical-align: middle;
          }
        }
        .shippingDetailText,
        .billingDetailText,
        .notificationDetailText {
          svg {
            fill: #c7c9c8;
          }
        }
      }
      .notificationGrid {
        margin-top: 1.5rem;
        .MuiTableContainer-root {
          .MuiTableRow-head {
            background: $primary-blue !important;
            th {
              background: $primary-blue !important;
            }
          }
          .MuiTableCell-body {
            padding: 0 !important;
          }
          .MuiTableBody-root {
            .MuiTableRow-root {
              .MuiTableCell-body {
                padding-right: 0 !important;
                padding-top: 0 !important;
                padding-bottom: 0 !important;
                padding-left: 1em !important;
              }
              th,
              td {
                padding: 0 !important;
              }
              th {
                padding-left: 1em !important;
                font-family: $font-family-primary;
              }
            }
          }
        }
        .emailcheckbox {
          accent-color: $cool-grey-4;
          height: 20px;
          width: 20px;
          vertical-align: -6px;
        }
        .emptyvalue {
          text-align: right;
          width: 50%;
        }
      }
      .notificationbuttons {
        padding-top: 1em;
        display: flex;
        flex-direction: row;
        gap: 2em;
        .notificationsave,
        .notificationdisregard {
          padding: 0;
          width: 25rem;

          background: $primary-blue;
          border: 3px solid $primary-blue;
          border-radius: 34px;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          padding: 0.5em 2.5em;
          color: $white;

          .MuiButton-label {
            font-family: $font-family-primary;
            font-style: normal;
            font-weight: 700;
            font-size: 18px;
            line-height: 22px;
            text-decoration-line: none;
            color: $white;
          }
        }

        .notificationdisregard {
          color: $primary-blue;
          border: 3px solid $primary-blue;
          background-color: $white;
          .MuiButton-label {
            color: $primary-blue;
          }
        }
      }
      .unsubscribeSection {
        float: right;
        .selectBtns {
          display: flex;
          gap: 1em;
        }
      }
      .optOutLanguage {
        font-family: 'urw-din' !important;
        font-weight: 400 !important;
        font-size: 16px !important;
        line-height: 22px !important;
        color: #333738 !important;
        text-align: justify;
      }
    }

    .billingSection {
      padding-bottom: 3em;
    }
    .shippingSection {
      padding-bottom: 0 !important;
    }

    .profileSection {
      .profileTitleSection {
        display: flex;
        align-items: baseline;
        gap: 1em;
        p {
          margin: 0;
        }
      }
    }
    .taxexemptsection {
      input[type='text']:invalid {
        border: 1px solid $alert-red;
      }
      padding-top: 1em;
      padding-bottom: 1em;
      .taxexempttitlesection {
        display: flex;
        gap: 1em;
        align-items: baseline;
        p {
          margin: 0;
        }
      }
      .taxexemptdetails {
        margin-top: 1em;
        .verifiedTaxExempt {
          display: flex;
          flex-direction: row;
          align-items: center;
          svg {
            fill: #8dc926;
          }
        }

        .taxexempt {
          display: flex;
          input {
            height: 18px;
            width: 18px;
          }
        }
        .tooltip {
          padding: 0 !important;
          margin: 0 !important;
          height: fit-content;
          .tooltipArea {
            margin-top: -1px;
            margin-left: 3px;
            .MuiButton-root {
              padding-top: 0.4em;
              .MuiButton-label {
                .MuiSvgIcon-root {
                  font-size: 2.125rem;
                }
              }
            }
          }
        }
        .pendingicon {
          color: $expiresoon;
          position: relative;
          top: 5px;
          font-size: 18px;
          padding-right: 5px;
        }
        .uploadtaxexempt {
          .filenamediv {
            color: $accent-blue;
            padding-left: 1em;
            padding-top: 0.3em;
            cursor: pointer;
            .filenamespan {
              text-decoration: underline;
              color: $accent-blue;
            }
            .fafileicon {
              position: relative;
              top: 2px;
              padding-right: 5px;
            }
            .facloseicon {
              position: relative;
              top: 3px;
              padding-left: 10px;
              font-weight: 400;
              font-size: 15px;
            }
          }
          .tooltip {
            padding: 0 !important;
            margin: 0 !important;
            height: fit-content;
            .tooltipArea {
              margin-top: -1px;
              margin-left: 3px;
              .MuiButton-root {
                padding-top: 0.4em;
                .MuiButton-label {
                  .MuiSvgIcon-root {
                    font-size: 2.125rem;
                  }
                }
              }
            }
          }
          .buttondiv {
            .MuiButton-root {
              background: none;
              background-color: $white !important;
              color: inherit;
              margin-top: 1%;
              margin-left: 0.3%;
              border: 3px solid $primary-blue !important;
              padding: 0.3em 3em 0.3em 3em;
              font: inherit;
              cursor: pointer;
              outline: inherit;
              .MuiButton-label {
                font-family: $font-family-primary;
                font-style: normal;
                font-weight: 700;
                font-size: 16px;
                line-height: 22px;
                color: $primary-blue;
              }
            }
          }
        }
      }
      .taxexemptdetailssection {
        font-weight: 700;
        font-size: 18px;
        line-height: 22px;
        color: $black;
        .errormessage {
          font-weight: 400;
          color: $alert-red;
          font-size: 13px;
        }
        .taxexemptfield {
          height: 30px;
          width: 44rem;

          @include max-screen(small) {
            width: 32rem !important;
          }
        }
        .taxexemptfieldheading {
          font-weight: 700;
          font-size: 18px;
          line-height: 22px;
          color: $black;
        }
        .taxexemptfieldsection {
          display: flex;
          flex-direction: column;
        }
      }
      .expirestatus {
        color: $textinput;
        font-weight: 400;
        font-size: 16px;
        padding-left: 1.5em;
      }
      .expiresoonicon {
        color: $expiresoon !important;
      }
      .updatetaxexemlink {
        font-weight: 700;
        padding-left: 1em;
      }
      .expired {
        color: $primary-red;
        font-size: 16px;
        font-weight: 400;
        padding-left: 1.5em;
      }
      .expiredicon {
        color: $primary-red;
        font-size: 15px;
      }
    }
    .taxexemptbuttons {
      padding-top: 1em;
      display: flex;
      flex-direction: row;
      gap: 2em;
      .taxexemptsave,
      .taxexemptdisregard {
        padding: 0;
        background: $primary-blue;
        border: 3px solid $primary-blue;
        border-radius: 34px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 0.5em 2.5em;
        color: $white;

        .MuiButton-label {
          font-family: $font-family-primary;
          font-style: normal;
          font-weight: 700;
          font-size: 18px;
          line-height: 22px;
          text-decoration-line: none;
          color: $white;
        }
      }

      .taxexemptdisregard {
        color: $primary-blue;
        border: 3px solid $primary-blue;
        background-color: $white;
        .MuiButton-label {
          color: $primary-blue;
        }
      }
    }
  }
}
