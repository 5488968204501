@import '../../assets/styles/_mixins.scss';
@import '../../assets/styles/_variables.scss';
@import '../../assets/styles/_typography.scss';

.related-programs {
  box-sizing: border-box;
  border: 1px solid $light-grey;
  background-color: $lightergrey;
  display: flex;
  padding: 1em 4em !important;
  flex-direction: column;
  gap: 0.5em;
  .popup-section {
    display: flex;
    flex-direction: row;
  }
  .pills-section {
    display: flex;
    gap: 0.7em;
    padding-top: 0.25em;
    flex-wrap: wrap;
    .MuiChip-colorPrimary {
      color: $white;
      background-color: $primary-blue;
      border: 3px solid $primary-blue !important;
      border-radius: 34px !important;
      height: fit-content;
      .MuiChip-label{
        white-space: break-spaces;
        text-align: center;
      }
      &:hover {
        color: $primary-blue;
        background-color: $white;
      }
    }
  }
  .popupClose {
    flex: 0 0 auto;
    color: rgba(0, 0, 0, 0.54);
    font-size: 3.085714285714286rem;
    transition: none !important;
    border-radius: 0% !important;
    width: -webkit-fill-available;
    display: flex !important;
    text-align: right;
    padding: 0em 0.1em !important;
    outline: none !important;
    box-shadow: none !important;
    background-color: $white !important;
    .MuiIconButton-label {
      width: 100%;
      display: block;
      align-items: inherit;
      justify-content: inherit;
      .MuiSvgIcon-root {
        transition: none !important;
        width: 0.8em !important;
        height: 0.8em !important;
      }
    }
  }
  p {
    font-family: $font-family-primary;
    color: $black;
    margin: 0 !important;
    text-align: left;
    font-size: 1.5rem;
  }
  .program-title {
    font-weight: 800;
  }
  .program-details {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .read-more {
    font-weight: 600;
    padding-left: 0.25em;
  }
  .tooltipArea {
    margin-top: -2px;
    .MuiTooltip-popper,
    .MuiTooltip-popperInteractive {
      will-change: unset !important;
      position: absolute;
      top: 24% !important;
      left: 24% !important;
      z-index: 199 !important;
      @include max-screen(medium) {
        top: 10% !important;
        left: 10% !important;
      }
      @include max-screen(small) {
        top: 3% !important;
        left: 3% !important;
      }
    }
    .MuiButton-root {
      padding: 0 !important;
      min-width: 35px;
      svg {
        fill: $cool-grey-8;
      }
    }
    .MuiTooltip-tooltip {
      background-color: white;
      color: black;
      border: 1px solid $cool-grey-8;
      min-width: 15rem;
      padding-bottom: 3em;
      box-shadow: 0 0 15px grey;
      width: 75rem;
      max-width: none;

      @include max-screen(tiny) {
        width: 20rem;
        max-width: none;
      }
      @include range-screen(xsmall, small) {
        width: 30rem;
        max-width: none;
      }
      @include range-screen(small, medium) {
        width: 50rem;
        max-width: none;
      }
      @include min-screen(medium) {
        width: 75rem;
        max-width: none;
      }
    }
    .MuiGrid-root {
      .MuiPaper-elevation1 {
        box-shadow: none !important;
      }
    }
  }
  .header-text {
    margin: 0 !important;
    color: $cool-grey-8;
    font-size: 1.7rem;
    font-weight: 500;
  }
}
