@import '../../assets/styles/_variables.scss';

.paymentmethod {
  hr {
    margin-left: 5%;
    margin-right: 0%;
    margin-top: 3%;
    margin-bottom: 3%;
  }
  .checkoutPaymentSection {
    .paymentArea {
      display: flex;
      flex-direction: row;
      .SNo {
        font-style: normal;
        font-weight: 700;
        font-size: 2.2rem;
        line-height: 0.3;
        padding: 1.5rem;
        color: $white;
        background: $primary-blue;
        aspect-ratio: 1/1;
        border-radius: 50%;
        margin-top: 0 !important;
        display: flex;
        align-items: center;
      }
      .Title {
        margin-top: 0 !important;
        font-family: $font-family-primary;
        p {
          font-style: normal;
          font-weight: 700;
          font-size: 24px;
          line-height: 41px;
          color: $primary-blue;
          font-family: $font-family-primary;
          vertical-align: middle;
          margin-bottom: 0 !important;
          margin-top: 0 !important;
        }
      }
      .reviewOrderEdit {
        margin-top: 0 !important;
        svg {
          color: $accent-blue;
        }
        a {
          text-decoration: underline;
          text-decoration: underline;
          font-style: normal;
          font-weight: 700;
          font-size: 18px;
          line-height: 42px;
          text-decoration-line: underline;
          color: $accent-blue;
        }
      }
    }
    .paymentType {
      p {
        padding-left: 4.2rem;
      }
      .paymentTypeHeader {
        padding-left: 4.2rem;
        margin-left: 2rem;
        margin-top: 1em;
        margin-bottom: 1em;
      }
    }
  }
  .paymentSelection {
    padding-left: 3.5em;
    .paymentTypeHeader {
      margin: 1em 0;
    }
  }
  .paymentheader {
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    color: $primary-blue;
    font-family: $font-family-primary;
  }

  .stepnobox {
    justify-content: center;
    align-items: center;
    background: $primary-blue;
    border: 3px solid $primary-blue;
    border-radius: 87px;
    color: $white;
    font-size: 18px;
    font-weight: 700;
    font-family: $font-family-primary;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    padding-left: 0.8em;
    padding-right: 0.8em;
    margin-right: 2%;
  }

  .divleft {
    float: left;
    width: 6%;
    margin-top: 2%;
    margin-left: 1%;
  }
  .divright {
    float: left;
    width: 93%;
    margin-top: 2%;
  }
  .spantext {
    color: $black;
    font-family: $font-family-primary;
    font-size: 16px;
    font-weight: 700;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    display: block;
  }

  .spanpaymenttype {
    color: $black;
    font-family: $font-family-primary;
    font-size: 16px;
    font-weight: 400;
    padding-right: 0.3em;
    position: relative;
    vertical-align: text-top;
    cursor: pointer;
  }

  .MuiButtonBase-root {
    justify-content: unset;
  }

  .radiodiv {
    background: $lily-white;
    width: -webkit-fill-available;
    align-items: baseline;
    display: flex;
    margin-bottom: 1rem;
    padding: 1rem;
    .tooltip {
      .tooltipArea {
        line-height: 0;
        .MuiSvgIcon-root {
          font-size: 2.2rem;
        }
      }
    }
  }

  .questionicon {
    font-style: normal;
    font-weight: 100 !important;
    font-size: 10px;
    color: $white;
    padding-bottom: 0.4em;
    padding-left: 0.1em;
    position: relative;
    bottom: 2px;
    right: 1px;
  }

  .radiobutton {
    height: 17px;
    width: 20px;
    position: relative;
    top: 2px;
    border: 14px solid black;
  }
  input[type='radio']:focus {
    outline: none;
    box-shadow: none;
  }
  input[type='radio']:checked {
    accent-color: $black;
  }

  .inputtext {
    padding: 1em;
    width: -webkit-fill-available;
    color: $textinput;
    font-size: 15px;
    font-weight: 400;
    border: 1px solid $light-grey;
    font-family: $font-family-primary;
  }
  .monthdiv {
    float: left;
    width: 23%;
  }
  .yeardiv {
    float: left;
    width: 23%;
  }
  .ccvdiv {
    float: left;
    width: 24%;
  }
  .creditdiv {
    .addressinfoheader {
      color: $black;
      font-style: normal;
      font-weight: 700;
      font-family: $font-family-primary;
      font-size: 18px;
      display: inline-block;
      padding-top: 1em;
      padding-left: 0rem;
      padding-right: 0.5em;
    }
    .addresconfirm {
      margin: 0 !important;
    }
    .checkbox {
      outline: none;
      color: $light-grey;
      margin-right: 0.5rem;
    }
  }
  .billlinfo {
    color: $black;
    font-size: 14px;
    font-family: $font-family-primary;
    display: inline-block;
    padding-left: 1.5em;
    width: -webkit-fill-available;
  }

  .unitdiv {
    float: left;
    margin-right: 0;
  }
  .citydiv {
    float: left;
    margin-right: 0;
    width: unset;
    input {
      width: -webkit-fill-available;
    }
  }
  .stdiv {
    float: left;
    margin-right: 0;
  }
  .postaldiv {
    margin-right: 0;
  }
  .addresspaymentdivs {
    display: flex;
    gap: 1em;
    .inputtext {
      padding: 1em;
      width: -webkit-fill-available;
      color: $textinput;
      font-size: 15px;
      font-weight: 400;
      border: 1px solid $light-grey;
      font-family: $font-family-primary;
    }
  }
  .countrydiv {
    .inputtext {
      padding: 1em;
      width: -webkit-fill-available;
      color: $textinput;
      font-size: 15px;
      font-weight: 400;
      border: 1px solid $light-grey;
      font-family: $font-family-primary;
    }
  }
}

input:checked {
  background: $primary-red;
  accent-color: $black;
}

.billing-form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  font-weight: bold;

  .billing-form-row-group {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 2rem;

    .text-error {
      color: $primary-red;
      font-size: 1.2rem;
      font-weight: normal;
    }
  }

  .billing-form-row {
    display: flex;
    flex-direction: column;
    width: 100%;
    .billing-form-row-header {
      font-size: 1.6rem;
      display: flex;
      justify-content: space-between;
    }
  }

  .billing-form-column {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    justify-content: space-evenly;
  }

  .text-error {
    color: $primary-red;
    font-size: 1.2rem;
    font-weight: normal;
  }

  input,
  select {
    padding: 1rem 0rem 1rem 1rem;
    border: 1px solid $light-grey;
  }

  input:focus,
  select:focus {
    outline: none;
  }
}
