@import '../../assets/styles/_mixins.scss';
@import '../../assets/styles/_variables.scss';
@import '../../assets/styles/_typography.scss';

.orderProcessingSection {
  padding: 4em 30em;
  .orderProcessingHeader {
    h1 {
      padding: 0 !important;
      margin: 0 !important;
      color: $alert-red;
    }
  }
  .orderProcessingContent {
    padding-top: 0.5em;
    padding-left: 0.25em;
    .orderProcessingRichText {
      a {
        color: $accent-blue;
        font-weight: 700;
        text-decoration: none !important;
      }
    }
  }
  .progressBar {
    text-align: center;
    padding: 2em 8em;

    .svg {
      fill: #cd163f !important;
    }
  }
}
