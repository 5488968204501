@import '../../assets/styles/_variables.scss';

.externalpaymentprocessing {
  color: $primary-blue;
}
.paymentRichText{
  margin-top: 3%;
  display: flex;
  justify-content: center;
  font-size: 2.375rem;
}