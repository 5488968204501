@import '../../assets/styles/_variables.scss';
@import '../../assets/styles/_typography.scss';

.LatestNewsArea {
  background: #17457a;
  .seeAllLatestNews {
    font-style: normal;
    font-weight: 700;
    text-transform: uppercase;
    color: $white;
    text-decoration: none;
    font-size: 30px;
    @include range-screen(tiny, small) {
      font-size: 24px;
      padding: 1em 0.25em;
    }
    
    @include range-screen(medium, large) {
      font-size: 28px;
    }
    svg {
      vertical-align: top;
      fill: $primary-red;
    }
  }
  .LatestNewsHeading {
    font-style: normal;
    font-weight: bolder;
    font-size: 54px;
    color: $white;
    margin: 0;

    @include range-screen(tiny, medium) {
      padding: 0.5em 1em !important;
      font-size: 36px;
    }

    @include min-screen(medium) {
      padding: 0.5em 1.5em !important;
      font-size: 44px;
    }
  }
  .LatestNewsDescription {
    font-style: normal;
    line-height: 29px;
    color: $white;
    margin: 0 !important;

    @include range-screen(tiny, medium) {
      padding: 0 2em !important;
      font-size: 18px;
    }

    @include min-screen(medium) {
      padding: 0 3.75em !important;
    }
  }
  .seeAllContentPara {
    margin: 0;

    @include range-screen(tiny, medium) {
      padding: 2em 1.8em;
    }

    @include min-screen(medium) {
      padding: 2em 4em;
    }
    svg {
      vertical-align: top;
      fill: #cd163f;
    }
  }

  .latest-news-items-container  {
    row-gap: 1em;
    display: flex;

    @include range-screen(tiny, medium) {
      padding: 0.5em 1.5em !important;
    }

    @include min-screen(medium) {
      padding: 0.5em 3.5em;
    }

    .MuiGrid-spacing-xs-3 {
      width: 100%;
      margin: 0;
    }

    .LatestNewsContainerGrid {
      row-gap: 1em;
      .LatestNewsItem {
        background: #17457a;
        text-align: left;
        height: 100%;
        box-shadow: none !important;
        .LatestNewsItemHeading {
          font-style: normal;
          font-weight: bolder;
          color: white;
          font-size: 24px;
          line-height: 29px;
          :hover {
            color: white;
          }
        }

        .LatestNewsItemDescription {
          font-style: normal;
          color: white;
          font-size: 24px;
          line-height: 29px;
          padding-top: 1em;
          padding-bottom: 1em;
        }

        .site-search-search-button {
          a {
            text-decoration: none;
          }
        }
      }
    }
  }
}
