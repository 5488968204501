@import '../../assets/styles/_mixins.scss';
@import '../../assets/styles/_variables.scss';
@import '../../assets/styles/_typography.scss';

.custombutton {
  .button {
    a {
      text-decoration: none;
    }
    .MuiButton-root {
      background: $white;
      border: 3px solid $white;
      border-radius: 34px;
      span {
        color: $primary-blue;
        font-style: normal;
        font-weight: 700;
        @include font-size(2);
        line-height: 22px;
      }
    }
  }
}
