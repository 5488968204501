@import '../../assets/styles/_mixins.scss';
@import '../../assets/styles/_variables.scss';
@import '../../assets/styles/_typography.scss';
.headermenu {
  float: right;
  display: flex;

  nav {
    a {
      color: $white;
      text-decoration: none;
      padding-right: 2em;
      @include font-size(1.8);
      font-weight: 400;
      outline: none;
      white-space: nowrap;
    }
  }
  span {
    height: 28px;
    border: 0.8px solid $white;
  }
}
