@import '../../assets/styles/_mixins.scss';
@import '../../assets/styles/_variables.scss';
@import '../../assets/styles/_typography.scss';

body {
  #fade-menu {
    display: none;
    @include min-screen(large) {
      display: block;
    }
    li {
      a {
        color: black !important;
        text-decoration: none !important;
      }
    }
  }

  .mobileDrawer {
    display: block;
    .CloseMenuDiv {
      text-align: center;
      .CloseMobNavMenu {
        padding: 1em;
        font-size: 1.5rem !important;
        color: black !important;
        text-decoration: none !important;
        border: 1px solid grey;
      }
    }

    @include min-screen(large) {
      display: none;
    }
    nav {
      a {
        color: black !important;
        text-decoration: none !important;
      }
    }
  }
}

.login {
  padding-left: 20px;
  width: fit-content;
  display: flex;
  padding-right: 20px !important;

  .username-container {
    display: flex;
    align-items: center;
    .loginLink {
      display: flex !important;
      width: fit-content;
      justify-content: flex-end;
      span {
        max-width: 200px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        display: inline-block;
      }
    }
  }

  .MuiButton-text {
    padding: 0 !important;
    width: fit-content;
    .MuiButton-label {
      width: fit-content;
      white-space: nowrap;
    }
  }

  .DesktopLoginMenu {
    position: fixed;
    z-index: 1999;
    top: 35px;
    .callout {
      float: left;
      background-color: #ffffff;
      background-image: -moz-linear-gradient(top, #ffffff, #ffffff);
      position: relative;
      color: #000;
      padding: 10px;
      border-radius: 3px;
      margin-left: -45px;
      margin-top: 46px;
      min-height: 50px;
      border: 1px solid #bbb;
    }

    .callout::before {
      content: '';
      width: 0px;
      height: 0px;
      border: 0.8em solid transparent;
      position: absolute;
    }

    .callout.bottom::before {
      left: 25%;
      top: -18px;
      border-bottom: 10px solid #fff;
    }

    .vertical-menu {
      // width: 260px;
      margin-right: 1em;
    }

    .vertical-menu a {
      background-color: #fff;
      color: black !important;
      display: block;
      padding: 5px;
      text-decoration: none;
    }

    .vertical-menu a:hover {
      background-color: #ccc;
      text-decoration: none !important;
    }

    display: none !important;
    @include min-screen(large) {
      display: block !important;
    }
  }
  .MobileLoginMenu {
    display: block !important;
    @include min-screen(large) {
      display: none !important;
    }
  }
  a {
    color: white;
    text-decoration: none;
    @include font-size(1.8);
    font-weight: 400;
    outline: none;
    font-size: 1.8rem;
    font-family: $font-family-primary !important;
  }

  .icon {
    fill: $white;
    margin-bottom: 0.5%;
    @include font-size(2.5);
    vertical-align: text-top;
    padding-right: 0.25em;

    @include max-screen(large) {
      vertical-align: middle;
    }
  }

  .PopOverRoot {
    z-index: 2000;
    top: 290px;
    margin-left: 65%;
    .MuiMenu-paper {
      ul {
        li {
          a {
            color: black !important;
            text-decoration: none !important;
          }
        }
      }
    }
  }
}
