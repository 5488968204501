@import '../../assets/styles/mixins.scss';
@import '../../assets/styles/_variables.scss';
@import '../../assets/styles/_typography.scss';

.checkout-page-main {
  flex-direction: row;
  @include min-screen(medium) {
    display: flex;
  }
}
.checkout-empty-cart {
  padding: 1em 3.8em;
  padding-right: 2rem;
  padding-top: 0 !important;
  .cartPageTitle {
    font-weight: 700;
    font-size: 36px;
    line-height: 43px;
    color: $primary-blue;
    font-family: $font-family-primary;
    margin: 0 !important;
    padding: 1em 0em !important;
    span {
      font-weight: normal;
    }
  }
  .red-title {
    color: $primary-red !important;
  }
}
