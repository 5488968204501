@import '../../assets/styles/_mixins.scss';
@import '../../assets/styles/_variables.scss';
@import '../../assets/styles/_typography.scss';

.tooltip {
  .MuiButton-root {
    min-width: 0;
    padding: 0 !important;
    width: fit-content;
    margin: 0 !important;
    .MuiButton-label {
      margin: 0;
      padding: 0;
    }
    .MuiSvgIcon-root {
      font-weight: 900;
      font-size: 100%;
      line-height: 18px;
      fill: $accent-blue !important;
    }
  }

  .MuiTooltip-popper {
    .MuiTooltip-tooltip {
      @include font-size(1.125);
      line-height: 15px;
    }
  }
}
