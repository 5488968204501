@import '../../assets/styles/_mixins.scss';
@import '../../assets/styles/_variables.scss';
@import '../../assets/styles/_typography.scss';

.manageAssignmentsDialog {
  .MuiDialog-paper {
    border-radius: 5px !important;
  }

  #manageAssignmentsTitle {
    height: 50px;
    background-color: $primary-blue;
    display: flex;
    align-items: center;
    .manageAssignmentsHeader {
      display: flex;
      color: $white;
      font-family: $font-family-primary;
      .headerColumn {
        p {
          font-family: $font-family-primary;
          font-size: 19px;
        }
      }
      .statusColumn {
        width: 200px;
        display: flex;
        align-items: baseline;
        .tooltipArea {
          .MuiButtonBase-root {
            top: 0;
            bottom: 0;
            padding-left: 7px !important;
            vertical-align: middle;
            .MuiSvgIcon-root {
              font-size: 2rem;
              fill: white !important;
            }
          }
        }
      }
      .emailColumn {
        display: flex;
        align-items: center;
        width: 290px;
      }
      .actionsColumn {
        display: flex;
        align-items: baseline;
        .tooltipArea {
          .MuiButtonBase-root {
            top: 0;
            bottom: 0;
            padding-left: 7px !important;
            vertical-align: middle;
            .MuiSvgIcon-root {
              font-size: 2rem;
              fill: white !important;
            }
          }
        }
      }
    }
    .MuiButtonBase-root {
      top: 15px;
      right: 15px;
      .MuiIconButton-label {
        svg {
          fill: $white;
        }
      }
    }
  }
  .manageAssignmentsTable {
    padding: 0;
    .assignmentsBodySection {
      tr {
        td {
          line-height: 2.8 !important;
          .rowBtnAction {
            padding: 0;
            margin: 0;
            width: 220px;
            .buttonFillBlue {
              background: $primary-blue;
              border: 3px solid $primary-blue;
              color: $white;
              border-radius: 34px;
              width: 100%;
              .MuiButton-label {
                font-style: normal;
                font-weight: 700;
                font-size: 15px;
                svg {
                  padding-right: 7px;
                }
              }
            }
            .buttonFillBlue:hover {
              background: $white;
              color: $primary-blue;
              border: 3px solid $primary-blue;
            }
          }
          .linkExpiredAction {
            width: 395px;
          }
          .notDownloadedAction {
            display: flex;
            justify-content: flex-start;
            width: unset !important;
          }
        }
      }
      tr:nth-child(odd) {
        background: #e9ebea !important;
        td {
          line-height: 2.8 !important;
          .rowBtnAction {
            padding: 0;
            margin: 0;
            width: 220px;
            background: #e9ebea;
          }
          .linkExpiredAction {
            width: 395px;
          }
          .notDownloadedAction {
            display: flex;
            justify-content: flex-start;
            width: unset !important;
          }
        }
      }
      .linkDownload {
        color: $toast-green;
        svg {
          font-size: 18px;
          vertical-align: text-bottom;
          padding-right: 5px;
        }
      }
      .linkExpire {
        color: $primary-red;
        svg {
          font-size: 18px;
          vertical-align: text-bottom;
          padding-right: 5px;
        }
      }
      .linkNotDownloaded {
        color: $pure-blue;
        svg {
          font-size: 18px;
          vertical-align: text-bottom;
          padding-right: 5px;
        }
      }
      .statusCell {
        padding: 0em 3.5em;
        padding-right: 0em;
        width: 185px;
        font-weight: 600;
        font-size: 14px;
        font-family: $font-family-primary;
      }
      .emailCell {
        flex-direction: column;
        width: 255px;

        .emailInput {
          padding: 0.5em;
          border: 1px solid $grayish-blue;
          width: fit-content;
        }
        .emailtextfield {
          font-family: $font-family-primary !important;
          color: $cool-grey-8 !important;
          font-style: normal;
          font-weight: 400 !important;
          font-size: 16px !important;
          line-height: 22px;
          width: fit-content;
          border: 1px solid $cool-grey-5 !important;
          background: $white !important;
          padding: 0.5em 0 0.5em 0.5em;
          margin-right: 2%;
        }

        .emailtextfielderror {
          font-family: $font-family-primary !important;
          color: $cool-grey-8 !important;
          font-style: normal;
          font-weight: 400 !important;
          font-size: 16px !important;
          line-height: 22px;
          border: 1px solid $alert-red !important;
          background: $white !important;
          padding: 0.5em 0 0.5em 0.5em;
          margin-right: 2%;
        }

        .errormessage {
          line-height: 2;
        }

        .emailtextfield:focus {
          outline: none !important;
          box-shadow: none !important;
        }
      }
    }
  }
  .resendDiv{
    display: flex;
    justify-content: center;
    .resendSuccess{
      color: green;
      padding-bottom: 1em;
    }
    .resendFailure{
      color: red;
      padding-bottom: 1em;
    }
  }
}
